import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import type { ContentProps } from './components/content';
import { Content } from './components/content';
import { ContentHeader } from './components/content-header';
import { DeliveryMethod } from './components/delivery-method';
import type { DeliveryMethodProps } from './components/delivery-method/DeliveryMethod';
import type { FooterProps } from './components/footer';
import { Footer } from './components/footer';
import type { HeaderProps } from './components/header';
import { Header } from './components/header';
import { LoyaltyPoints } from './components/loyalty-points';
import type { LoyaltyPointsProps } from './components/loyalty-points/LoyaltyPoints';
import { OrderDate } from './components/order-date';
import type { OrderDateProps } from './components/order-date/OrderDate';
import type { OrderDetailsProps } from './components/order-details';
import { OrderDetails } from './components/order-details';
import type { OrderNumberProps } from './components/order-number/OrderNumber';
import { OrderNumber } from './components/order-number/OrderNumber';
import { OrderStatus } from './components/order-status';
import type { OrderStatusProps } from './components/order-status/OrderStatus';
import { PaymentDetails } from './components/payment-details';
import type { PaymentDetailsProps } from './components/payment-details/PaymentDetails';
import { StoreAddress } from './components/store-address';

type OrderCompleteCompositionProps = {
  Header: React.FC<HeaderProps>;
  Footer: React.FC<FooterProps>;
  Content: React.FC<ContentProps>;
  ContentHeading: React.FC;
  OrderStatus: React.FC<OrderStatusProps>;
  OrderDetails: React.FC<OrderDetailsProps>;
  LoyaltyPoints: React.FC<LoyaltyPointsProps>;
  OrderDate: React.FC<OrderDateProps>;
  OrderNumber: React.FC<OrderNumberProps>;
  DeliveryMethod: React.FC<DeliveryMethodProps>;
  PaymentDetails: React.FC<PaymentDetailsProps>;
  StoreAddress: React.FC;
};

export type OrderCompleteProps = {
  boxProps?: BoxProps<Theme>;
  children?: React.ReactNode;
};
export const OrderComplete: OrderCompleteCompositionProps & FC<PropsWithChildren<OrderCompleteProps>> = ({ boxProps, children }) => {
  const { orderCompleteScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const orderCompleteOptions = isMobile ? orderCompleteScreen?.wrapperMobile : orderCompleteScreen?.wrapper;
  return (
    <Box {...boxProps} {...orderCompleteOptions?.style}>
      {children}
    </Box>
  );
};

OrderComplete.Content = Content;
OrderComplete.Header = Header;
OrderComplete.Footer = Footer;
OrderComplete.ContentHeading = ContentHeader;
OrderComplete.OrderStatus = OrderStatus;
OrderComplete.OrderDetails = OrderDetails;
OrderComplete.LoyaltyPoints = LoyaltyPoints;
OrderComplete.OrderDate = OrderDate;
OrderComplete.OrderNumber = OrderNumber;
OrderComplete.DeliveryMethod = DeliveryMethod;
OrderComplete.StoreAddress = StoreAddress;
OrderComplete.PaymentDetails = PaymentDetails;
