import { OrderFlowEnum } from '@goparrot/common';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import type { ReadCartDto } from '@goparrot/order-sdk';
import { OrderComplete as OrderCompleteComponent } from '@webstore-monorepo/features/order-complete';
import { useVerifiedOrder } from '@webstore-monorepo/shared/api/cart-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Separator } from '@webstore-monorepo/ui/separator';
import React from 'react';
import { useParams } from 'react-router-dom';

import { WrappedInlineLoader } from '../../../components/uielements/loaders';
import { APP_PATHS } from '../../../routes/paths';
import { useAppHistory } from '../../../shared/hooks/use-app-history';

export const SuccessPageNew = () => {
  const history = useAppHistory();
  const params = useParams<{ orderId: string }>();
  const { user } = useUserContextState();
  const isAuthenticated = user.type === UserTypeEnum.AUTHENTICATED;
  const webstore = useWebStore();
  const guestLastOrderStored = sessionStorage.getItem('last_order');
  const guestLastOrder: ReadCartDto = JSON.parse(guestLastOrderStored ?? '{}');

  const { orderCompleteScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const orderCompleteOptions = isMobile ? orderCompleteScreen?.wrapperMobile : orderCompleteScreen?.wrapper;
  const { data: lastOrder } = useVerifiedOrder(webstore.merchantId, webstore.storeId, params?.orderId, false, {
    enabled: isAuthenticated,
    onSuccess: (data) => {
      if (guestLastOrder._id === data._id) {
        sessionStorage.removeItem('last_order');
      }
    },
    initialData: !isAuthenticated ? guestLastOrder : undefined,
  });
  const isDigitalCart = lastOrder?.flow === OrderFlowEnum.DIGITAL;

  const handleCloseModal = () => {
    history.push(APP_PATHS.home);
  };

  const handleContinue = () => {
    return history.push(APP_PATHS.home);
  };

  return lastOrder ? (
    <div style={{ padding: 0, minHeight: '100%' }}>
      <OrderCompleteComponent boxProps={{ height: '100%', backgroundColor: 'white' }}>
        <OrderCompleteComponent.Header onClose={handleCloseModal} title={orderCompleteOptions?.header?.title?.options?.text ?? 'Order Complete'} />
        <OrderCompleteComponent.Content boxProps={{ flexGrow: 1, flexShrink: 1, overflow: 'scroll' }}>
          <OrderCompleteComponent.ContentHeading />
          {!isDigitalCart ? (
            <>
              <Separator />
              <OrderCompleteComponent.OrderStatus lastOrder={lastOrder} />
              <Separator />
              <OrderCompleteComponent.DeliveryMethod lastOrder={lastOrder} />
            </>
          ) : null}
          <Separator />
          <OrderCompleteComponent.OrderDetails isAuthUser={isAuthenticated} lastOrder={lastOrder} />
          <OrderCompleteComponent.LoyaltyPoints isAuthUser={isAuthenticated} orderId={lastOrder._id} />
          <Separator mb={4} />
          <OrderCompleteComponent.OrderDate lastOrder={lastOrder} />
          <Separator mb={4} />
          <OrderCompleteComponent.OrderNumber orderNumber={lastOrder.orderNumber} />
          <Separator mb={4} />
          <OrderCompleteComponent.StoreAddress />
          <Separator mb={4} />
          <OrderCompleteComponent.PaymentDetails lastOrder={lastOrder} />
        </OrderCompleteComponent.Content>
        <OrderCompleteComponent.Footer onProceed={handleContinue}></OrderCompleteComponent.Footer>
      </OrderCompleteComponent>
    </div>
  ) : (
    <WrappedInlineLoader />
  );
};

export default SuccessPageNew;
