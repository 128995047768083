import styled from 'styled-components';

const mainColor = getComputedStyle(document.documentElement).getPropertyValue('--checkbox-checked-color');

export const Input = styled.input`
  border: 1px solid #ccc;
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    &[type='checkbox']:focus {
      border-color: var(--checkbox-checked-color);
      outline: none;
    }
    &[type='checkbox'] {
      --active: var(--checkbox-checked-color);
      --active-inner: #fff;
      --border: #9ca3af;
      --background: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 18px;
      width: 19px;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0 8px 0 0;
      cursor: pointer;
      border: 2px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      border-radius: 4px;

      &:disabled {
        border-color: #d1d5db;
      }

      &:after {
        content: '';
        width: 6px;
        height: 10px;
        border: 3px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 4px;
        top: 1px;
        transform: rotate(var(--r, 20deg));
        display: block;
        position: absolute;
        box-sizing: border-box;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
      }

      &:checked {
        // these are shortcuts for not to write twice, the same rule, for example here
        //--b will change the background color of checkbox specified above when it's checked, to --active color
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        --r: 43deg;
        outline: none;
        border-radius: 4px;
        &:disabled {
          border-color: transparent;
          background-color: ${mainColor}4d;
        }
      }

      & + label {
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        margin: 0;
      }
    }
  }
`;
