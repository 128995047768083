import type { IPaymentTransaction } from '@goparrot/payment-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export const useGetTransaction = (transactionId?: string): UseQueryResult<IPaymentTransaction> => {
  const getTransaction = async (transactionId?: string): Promise<IPaymentTransaction> => {
    return axios.get(`/api/v1/transactions/${transactionId}`);
  };
  return useQuery([QueryKeysEnum.getPrevTransaction, transactionId], async () => !!transactionId && getTransaction(transactionId), {
    enabled: !!transactionId,
    staleTime: Infinity,
    retry: false,
  });
};
