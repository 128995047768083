import type { IReadStoreFeaturesFlagsDto } from '@goparrot/store-v2-sdk';

import { getUserAgentInfo } from '../../utils/user-agent';
import type { IDigitalWalletsFeaturesFlags } from './FeatureFlagsProvider';

enum MobileOs {
  ANDROID = 'android',
  IOS = 'ios',
}

export const getDigitalWalletsFeaturesFlags = (featuresFlags: IReadStoreFeaturesFlagsDto): IDigitalWalletsFeaturesFlags => {
  const { os, device } = getUserAgentInfo();
  const isReactNativeWebView: boolean = !!window.ReactNativeWebView;

  let isApplePayEnabled = !!featuresFlags.isApplePayEnabled;
  let isGooglePayEnabled = !!featuresFlags.isGooglePayEnabled;

  if (isReactNativeWebView && device.type) {
    const isIOS = os.name?.toLowerCase() === MobileOs.IOS;

    isApplePayEnabled = isIOS && !!featuresFlags.isApplePayMobileEnabled;
    isGooglePayEnabled = !!(isIOS ? false : featuresFlags.isGooglePayMobileAndroidEnabled);
  }

  return {
    isApplePayEnabled,
    isGooglePayEnabled,
  };
};
