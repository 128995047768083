import { OrderDelayedInfoUtil } from '@goparrot/order-sdk';
import { DelayedOrdersUtils, DiningOptionsUtil, ReceiveMethodEnum } from '@goparrot/store-v2-sdk';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { ClockIcon } from '@webstore-monorepo/shared/icons';
import { DeliveryIcon } from '@webstore-monorepo/shared/icons';
import { PenIcon } from '@webstore-monorepo/shared/icons';
import { PickupIcon } from '@webstore-monorepo/shared/icons';
import { getConvertedOrderPrepTime } from '@webstore-monorepo/shared/utils/cart';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useMemo, useRef } from 'react';
import { Pressable } from 'react-native';

type OrderSetupStatusBarProps = {
  onChangeOrderSetup: () => void;
};
export const OrderSetupStatusBar: FC<OrderSetupStatusBarProps> = ({ onChangeOrderSetup }) => {
  const { orderSetupStatusBar } = useComponentsConfig();
  const contentRef = useRef<HTMLDivElement>(null);
  const webstore = useWebStore();
  const cart = useCartState();
  const store = useStoreState();
  const { delayedInfo, diningOptionInfo } = cart || {};
  const convertedPrepTime = getConvertedOrderPrepTime(cart, store);
  const { isMobile } = useWindowDimensions();
  const showOrderPrepTime = webstore.metadata.showASAPOrderPreptime;

  const delayedInfoMessage = useMemo(() => {
    if (OrderDelayedInfoUtil.isASAP(cart)) {
      return `ASAP${showOrderPrepTime && convertedPrepTime ? ` (~${convertedPrepTime})` : ''}`;
    }
    const isDeliveryRequired = DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo.type);
    const receiveMethod = isDeliveryRequired ? ReceiveMethodEnum.DELIVERY : ReceiveMethodEnum.PICKUP;
    const orderTime = DelayedOrdersUtils.getFormattedIntervalForSlot(store, receiveMethod, delayedInfo?.date as unknown as string);

    return webstore.metadata.hideDelayedOrderTime ? delayedInfo?.humanReadable : `${delayedInfo?.humanReadable}, ${orderTime}`;
  }, [cart, store, delayedInfo?.date, delayedInfo?.humanReadable, webstore.metadata.hideDelayedOrderTime, convertedPrepTime, showOrderPrepTime]);

  const readableOption = DiningOptionsUtil.getHumanReadableName(diningOptionInfo?.type, store as any);

  // TODO: replace with getSelectedDiningOptionName from shared-ui utils
  const selectedDiningOptionName = useMemo(() => {
    let fullReadable = '';
    if (readableOption.toLowerCase().includes('deliver')) {
      fullReadable = diningOptionInfo.details.address?.formattedAddress ? `Deliver to ${diningOptionInfo.details.address?.formattedAddress}` : readableOption;
      return isMobile ? readableOption : fullReadable;
    }
    if (readableOption.toLowerCase().includes('pickup')) {
      fullReadable = store.pickup?.address ? `${readableOption} from ${store.pickup?.address}` : readableOption;
      return isMobile ? readableOption : fullReadable;
    }
  }, [readableOption, diningOptionInfo.details.address, store.pickup, isMobile]);

  return (
    <Box
      paddingTop={4}
      paddingBottom={4}
      paddingLeft={2}
      paddingRight={2}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      {...orderSetupStatusBar?.wrapper?.style}
    >
      <Pressable
        accessibilityLabel="change order setup"
        accessibilityHint="press on the order type bar to open the page and change"
        accessibilityRole="button"
        testID="order-setup-status-bar"
        onPress={onChangeOrderSetup}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}
      >
        <Box
          testID="order-setup-status-orderType"
          ref={contentRef}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="gray100"
          marginRight="xxs"
          borderTopStartRadius="rounded"
          borderBottomStartRadius="rounded"
          {...orderSetupStatusBar?.wrapper?.orderType?.style}
        >
          <Box
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexBasis="auto"
            flexGrow={1}
            flexShrink={1}
            paddingTop={3}
            paddingBottom={3}
            paddingLeft={isMobile ? 3 : 4}
            paddingRight={isMobile ? 4 : 5}
          >
            {!isMobile ? (
              readableOption ? (
                <Box marginRight={1} testID="order-setup-status-orderType-icon">
                  {readableOption.toLowerCase().includes('deliver') ? (
                    <DeliveryIcon
                      testID="order-setup-status-orderType-delivery-icon"
                      fill={orderSetupStatusBar?.wrapper?.text?.style?.color}
                      stroke="transparent"
                    />
                  ) : (
                    <PickupIcon
                      testID="order-setup-status-orderType-pickup-icon"
                      fill={orderSetupStatusBar?.wrapper?.text?.style?.color}
                      stroke="transparent"
                    />
                  )}
                </Box>
              ) : null
            ) : null}
            <Text lineHeight={16} fontSize="s" marginRight={1} {...orderSetupStatusBar?.wrapper?.text?.style}>
              {selectedDiningOptionName ?? 'Select dining option'}
            </Text>
          </Box>
        </Box>
        <Box
          testID="order-setup-status-dateTime"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="gray100"
          style={{ borderRadius: 0 }}
          {...orderSetupStatusBar?.wrapper?.dateTime?.style}
        >
          <Box
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexBasis="auto"
            flexGrow={1}
            flexShrink={1}
            paddingTop={3}
            paddingBottom={3}
            paddingLeft={isMobile ? 3 : 4}
            paddingRight={isMobile ? 4 : 5}
            marginRight="xxs"
          >
            {!isMobile ? (
              <Box marginRight={1}>
                <ClockIcon testID="order-setup-status-dateTime-clock-icon" stroke={orderSetupStatusBar?.wrapper?.text?.style?.color} fill="transparent" />
              </Box>
            ) : null}
            <Text lineHeight={16} fontSize="s" {...orderSetupStatusBar?.wrapper?.text?.style}>
              {delayedInfoMessage}
            </Text>
          </Box>
        </Box>
        <Button
          testID="order-setup-status-changeButton"
          onPress={onChangeOrderSetup}
          paddingLeft={isMobile ? 4 : 6}
          paddingRight={isMobile ? 4 : 6}
          buttonStyle={{
            minWidth: isMobile ? 48 : 95,
            minHeight: contentRef.current?.getBoundingClientRect()?.height ?? 36,
            borderTopEndRadius: 'rounded',
            borderBottomEndRadius: 'rounded',
            ...orderSetupStatusBar?.wrapper?.changeButton?.style,
          }}
        >
          {isMobile ? <PenIcon width={14} height={14} stroke="transparent" fill="primaryButtonTextColor" /> : 'Change'}
        </Button>
      </Pressable>
    </Box>
  );
};
