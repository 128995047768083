import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import * as React from 'react';

export const ContentHeader: React.FC = () => {
  const { orderCompleteScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const orderCompleteOptions = isMobile ? orderCompleteScreen?.wrapperMobile : orderCompleteScreen?.wrapper;
  const { logoUrl } = orderCompleteOptions?.content?.options || {};

  return (
    <Box marginBottom={8} testID="order-complete-header">
      {logoUrl ? (
        <Box alignItems="center">
          <img
            className="logo"
            src={logoUrl}
            alt="Order Complete"
            style={{
              maxWidth: 512,
              maxHeight: 512,
            }}
          />
        </Box>
      ) : null}
      <Text fontSize="xl" fontWeight="700" paddingTop={4}>
        {orderCompleteOptions?.header?.subtitle?.options.text || 'Your order has been received'}
      </Text>
      <Text paddingTop={4} fontSize="m">
        {orderCompleteOptions?.content?.text?.options.text || 'Thank you for shopping! You should receive an email confirmation shortly.'}
      </Text>
    </Box>
  );
};
