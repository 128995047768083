import { AxiosException } from '@goparrot/common';
import { HttpStatus } from '@nestjs/common';
import type { AxiosError } from 'axios';

export interface IErrorToJson extends Record<string, any> {
  name: string;
  message: string;
  stack?: string;
  [key: string]: unknown;
}

export function errorToJSON(error: Error): IErrorToJson {
  if (error instanceof AxiosException) {
    const result = error.toJSON() as IErrorToJson;
    // Solution for safe transfer through postMessage
    return JSON.parse(JSON.stringify(result));
  }

  return {
    message: error.message,
    name: error.name,
    stack: error.stack,
  };
}

export function getSpecificErrorForGuestForm(error: AxiosError): string {
  const errorData = error.response?.data;
  if (errorData && [HttpStatus.BAD_REQUEST, HttpStatus.INTERNAL_SERVER_ERROR].includes(errorData.statusCode)) {
    let errorMessage = '';
    errorData.message.find(({ path }: { path: string; message: string }) => {
      if (path.includes('email')) {
        errorMessage = 'Provided email address is invalid.';
      }
    });
    return errorMessage;
  }
  return 'Theres a problem with the user form.';
}

export function getProviderSpecificErrorData(error: Error) {
  if (error instanceof AxiosException) {
    const errorData = error.response?.data;

    if (errorData && [HttpStatus.BAD_REQUEST, HttpStatus.INTERNAL_SERVER_ERROR, HttpStatus.NOT_FOUND].includes(errorData.statusCode)) {
      let errorCode;
      let errorMessage;

      if (Array.isArray(errorData.error)) {
        errorCode = errorData.error[0]?.code;
      } else if (errorData.code) {
        errorCode = errorData.code;
      }

      if (errorCode) {
        if (errorCode === 'INVALID_EMAIL') {
          errorMessage = 'Provided email address is invalid. Please update email in your account and try again.';
        } else if (errorCode === 'CARD_DECLINED_VERIFICATION_REQUIRED') {
          errorMessage = 'Your card was declined. Please contact your bank for more information.';
        } else {
          errorMessage = `Unfortunately there was an error while processing your payment.<br />${errorCode ? `Error Code: ${errorCode}` : ''}`;
        }
      }

      errorData.errorMessage = errorMessage || errorData.message;

      return errorData;
    }
  }
}
