import { useGetCategoryNavbarItems } from '@webstore-monorepo/shared/api/cart-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import { SearchIcon } from '@webstore-monorepo/shared/icons';
import type { ICategory } from '@webstore-monorepo/shared/interfaces';
import { onKeyEnterAndSpace } from '@webstore-monorepo/shared/utils/forms';
import { Box, ColFull, ScrollableBox } from '@webstore-monorepo/ui/box';
import { Input } from '@webstore-monorepo/ui/input';
import type { RefObject } from 'react';
import React, { createRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';

import { CategoryMenuNavBarItem } from './components/category-menu-nav-bar-item';
import { checkIfItemsCoverWidth } from './dimentions';

export type CategoryMenuNavBarProps = {
  selectedItem?: string;
  selectedMenuConceptUniqueNames?: string[];
  onCategoryItemClick: (category: ICategory, index: number) => void;
  onSearchChange?: (searchString: string) => void;
  onHideSearchBar?: () => void;
};

export const CategoryMenuNavBar = forwardRef(
  ({ selectedItem, selectedMenuConceptUniqueNames, onCategoryItemClick, onSearchChange, onHideSearchBar }: CategoryMenuNavBarProps, innerRef) => {
    const webstore = useWebStore();
    const [searchInp, setSearchInp] = useState<string>('');
    const categoryMenuParentRef = useRef<HTMLElement>();
    const categoryItemsRef = useRef<RefObject<HTMLDivElement>[]>([]);
    const categories = useGetCategoryNavbarItems(selectedMenuConceptUniqueNames);
    const { isMobile } = useWindowDimensions();
    const { categoriesNavBar, menuItemCard, menuItemCardMobile } = useComponentsConfig();
    const { analytics } = usePlatformStoreState();
    const [showSearch, setShowSearch] = useState(false);
    const [selectedItemElement, setSelectedItemElement] = useState<HTMLDivElement | null>(null);
    const showIcons = categories?.some((category) => category?.metadata?.icon || category?.metadata?.selectedIcon);
    const [isItemsCoverParent, setItemsCoverParent] = useState(true);
    const showCategories = categoriesNavBar?.wrapper?.options?.show && categories?.length;
    // @ts-ignore
    const { topSellingCategoryUuid } = webstore.metadata;

    useEffect(() => {
      if (categories) {
        setItemsCoverParent(checkIfItemsCoverWidth(categoryItemsRef?.current, categoryMenuParentRef.current));
      }
    }, [categories]);

    const handleFocusCategory = useCallback(
      (category: ICategory, index: number) => (event: KeyboardEvent) => {
        onKeyEnterAndSpace(event as unknown as React.KeyboardEvent, () => onCategoryItemClick(category, index));
      },
      // we can't trust if onCategoryItemClick is passed with useCallback
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    useEffect(() => {
      categoryItemsRef.current?.forEach((item, index) => {
        item.current?.addEventListener('keyup', handleFocusCategory(categories[index], index));
      });
      return () => {
        categoryItemsRef.current?.forEach((item, index) => {
          item.current?.removeEventListener('keyup', handleFocusCategory(categories[index], index));
        });
      };
    }, [categories, handleFocusCategory]);

    const handleItemClick = (index: number) => (category: ICategory) => {
      analytics.track('category_select', {
        uid: category.uniqueName,
      });
      onCategoryItemClick(category, index);
    };

    const handleToggleSearchBar = () => {
      const newState = !showSearch;
      setShowSearch(newState);
      setSearchInp('');
      if (!newState) {
        onHideSearchBar && onHideSearchBar();
      }
    };

    const handleSearchInputChange = (value: string) => {
      setSearchInp(value);
      onSearchChange && onSearchChange(value);
    };

    const handleClearInput = () => {
      setSearchInp('');
      onHideSearchBar && onHideSearchBar();
    };

    useEffect(() => {
      if (selectedItem && categoryItemsRef.current && categories) {
        const foundIndex = categories?.findIndex((item) => item.uniqueName === selectedItem);
        setSelectedItemElement(categoryItemsRef?.current[foundIndex]?.current);
      }
    }, [categories, selectedItem]);

    const renderSearchBox = () => (
      <Box testID="search-box" paddingTop={2} paddingBottom={2} width="100%">
        <Box maxWidth={isMobile ? '100%' : 600} flexDirection="row" justifyContent="center" alignItems="center">
          <Box
            borderRadius="rounded"
            backgroundColor="white"
            flexDirection="row"
            alignItems="center"
            flexGrow={1}
            marginRight={isMobile ? 'none' : 3}
            overflow="hidden"
            {...categoriesNavBar?.searchWrapper?.searchBlock?.style}
          >
            <Box width={36} height={36} justifyContent="center" alignItems="center" padding={2}>
              <SearchIcon
                fill="transparent"
                stroke={categoriesNavBar?.searchWrapper?.searchInputIcon?.style?.color}
                {...categoriesNavBar?.searchWrapper?.searchInputIcon?.style}
              />
            </Box>
            <Input
              accessibilityLabel="Search input"
              accessibilityHint="Search input to search for items"
              placeholder={categoriesNavBar?.searchWrapper?.options?.searchInputPlaceholder ?? 'What are you craving?'}
              borderWidth={0}
              backgroundColor="transparent"
              autoFocus={showSearch}
              value={searchInp}
              // @ts-ignore
              onChangeText={handleSearchInputChange}
              color={isMobile ? menuItemCardMobile?.title?.text?.style?.color : menuItemCard?.title?.text?.style?.color}
              fontFamily={isMobile ? menuItemCardMobile?.title?.text?.style?.fontFamily : menuItemCard?.title?.text?.style?.fontFamily}
              {...categoriesNavBar?.searchWrapper?.searchInput?.style}
              fullWidth
            />
            {searchInp !== '' ? (
              <Pressable accessibilityLabel="Clear Search" accessibilityHint="Press to clear search" accessibilityRole="button" onPress={handleClearInput}>
                <Box
                  width={24}
                  height={24}
                  backgroundColor={categoriesNavBar?.searchWrapper?.searchInputCloseIcon?.style?.backgroundColor ?? 'gray100'}
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="rounded"
                  padding={1}
                  marginRight={2}
                  marginLeft={2}
                >
                  <CloseIcon
                    width={12}
                    height={12}
                    fill="transparent"
                    stroke={categoriesNavBar?.searchWrapper?.searchInputCloseIcon?.style?.color}
                    {...categoriesNavBar?.searchWrapper?.searchInputCloseIcon?.style}
                  />
                </Box>
              </Pressable>
            ) : null}
          </Box>
        </Box>
      </Box>
    );

    return categoriesNavBar?.wrapper?.options?.show || categoriesNavBar?.searchWrapper?.options?.show ? (
      <Box ref={innerRef} testID="category-menu-navbar" {...categoriesNavBar?.wrapper?.style}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          minHeight={70}
          {...(showSearch ? { borderBottomColor: 'white', borderBottomWidth: 1 } : {})}
        >
          <ColFull
            ref={categoryMenuParentRef}
            paddingLeft={1}
            paddingRight={1}
            flexShrink={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            {!isMobile && categoriesNavBar?.searchWrapper?.options?.show ? (
              <Pressable
                accessible={true}
                accessibilityLabel="toggle search"
                accessibilityHint="press on the search icon to open or close search input"
                accessibilityRole="button"
                style={{ marginTop: 6, marginBottom: 6, marginRight: -36, zIndex: 100 }}
                onPress={handleToggleSearchBar}
              >
                <Box
                  testID="search-button"
                  height={36}
                  width={36}
                  backgroundColor="gray100"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="rounded"
                  padding={2}
                  marginRight={9}
                  {...categoriesNavBar?.searchWrapper?.style}
                >
                  {showSearch ? (
                    <CloseIcon
                      fill="transparent"
                      stroke={categoriesNavBar?.searchWrapper?.icon?.style?.color}
                      {...categoriesNavBar?.searchWrapper?.icon?.style}
                    />
                  ) : (
                    <SearchIcon
                      fill="transparent"
                      stroke={categoriesNavBar?.searchWrapper?.icon?.style?.color}
                      {...categoriesNavBar?.searchWrapper?.icon?.style}
                    />
                  )}
                </Box>
              </Pressable>
            ) : null}
            {showCategories ? (
              <ScrollableBox
                itemRefs={categoryItemsRef}
                testID="category-navbar-scroll"
                showArrows={true}
                flexDirection="row"
                alignItems="center"
                scrollToElement={selectedItemElement}
                parentWidth="98%"
                textColor={categoriesNavBar?.wrapper?.menuBlock?.style?.color ?? 'black'}
                arrowBackground={categoriesNavBar?.wrapper?.style?.backgroundColor}
                {...categoriesNavBar?.wrapper?.menuBlock?.style}
                justifyContent={isItemsCoverParent ? 'flex-start' : categoriesNavBar?.wrapper?.menuBlock?.style?.justifyContent ?? 'center'}
                {...(!isMobile ? { paddingLeft: categoriesNavBar?.searchWrapper?.options?.show ? 9 : 'none' } : {})}
                marginLeft={isMobile ? 4 : categoriesNavBar?.wrapper?.menuBlock?.style?.marginLeft}
                marginRight={isMobile ? 4 : categoriesNavBar?.wrapper?.menuBlock?.style?.marginRight}
              >
                {categories?.map((item, index) => (
                  <CategoryMenuNavBarItem
                    key={item.uniqueName}
                    ref={(categoryItemsRef.current[index] = createRef())}
                    item={item}
                    isTopItem={item.uniqueName === topSellingCategoryUuid}
                    selected={selectedItem === item.uniqueName}
                    showIcon={showIcons}
                    onItemClick={handleItemClick(index)}
                  />
                ))}
              </ScrollableBox>
            ) : showSearch ? (
              renderSearchBox()
            ) : null}
          </ColFull>
        </Box>
        {showCategories ? (
          (isMobile ? categoriesNavBar?.searchWrapper?.options?.show : showSearch) ? (
            <Box flexDirection="row" alignItems="center" justifyContent="center">
              <ColFull flexShrink={1} paddingLeft={isMobile ? 4 : 'none'} paddingRight={isMobile ? 4 : 'none'}>
                {renderSearchBox()}
              </ColFull>
            </Box>
          ) : null
        ) : null}
      </Box>
    ) : null;
  },
);
