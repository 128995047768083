import React, { forwardRef } from 'react';

import * as s from './Input.styled';

type Props = React.HTMLProps<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { type = 'text', className, ...restProps } = props;

  // @ts-ignore
  return <s.Input ref={ref} type={type} className={`input ${className ?? ''}`} {...restProps} />;
});

Input.displayName = 'Input';

export default Input;
