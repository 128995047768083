import type { IPromotionWithApplicationState } from '@goparrot/promotions-sdk';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useIsFetching } from '@webstore-monorepo/shared/contexts/react-query-provider';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MENU_PATHS } from '../../../../../pages/paths';
import { useAppContextState } from '../../../../../shared/contexts/app-context-provider';
import { QueriesKeysEnum } from '../../../../../shared/queries/queries-keys-enum';

export const usePromoPopupState = () => {
  const location = useLocation();
  const { promotions } = useAppContextState();
  const isFetching = useIsFetching(QueriesKeysEnum.applicablePromotions);
  const { promotions: appliedPromotions } = useCartState();
  const [viewedPromos, setViewed] = React.useState<string[]>([]);
  const [rejectedPromos, setRejected] = React.useState<string[]>([]);
  const [onHold, setOnHold] = React.useState(false);
  const [newApplicablePromos, setNewApplicablePromos] = useState<IPromotionWithApplicationState[]>([]);

  const updateViewedPromos = React.useCallback((uuid: string) => {
    setViewed((prevState) => [...prevState, uuid]);
    setOnHold(true);
  }, []);
  const updateRejectedPromos = React.useCallback((uuid: string) => {
    setRejected((prevState) => [...prevState, uuid]);
    setOnHold(true);
  }, []);

  React.useEffect(() => {
    if (!isFetching) {
      setViewed((prevState) => {
        return prevState.filter((uuid) => !appliedPromotions.some((p) => p.uuid === uuid));
      });
    }
  }, [appliedPromotions, isFetching]);

  useEffect(() => {
    if (!isFetching) {
      setNewApplicablePromos(
        promotions?.filter(({ isApplicable, promotion }) => isApplicable && ![...viewedPromos, ...rejectedPromos].includes(promotion.uuid)) ?? [],
      );
    }
  }, [isFetching, promotions, rejectedPromos, viewedPromos]);

  React.useEffect(() => {
    if (!newApplicablePromos.length) return;

    if (location.pathname === MENU_PATHS.promos) {
      const uuids = newApplicablePromos.map(({ promotion }) => promotion.uuid);

      setViewed((state) => [...state, ...uuids]);
    }
  }, [location.pathname, newApplicablePromos]);

  React.useEffect(() => {
    setOnHold(false);
  }, [promotions]);

  return { newApplicablePromos, setAsViewedPromo: updateViewedPromos, setAsRejected: updateRejectedPromos, onHold };
};
