import { StoreItemValidityEnum } from '@goparrot/common';
import type { IOrderElement } from '@goparrot/order-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { Platform } from 'react-native';

interface PackingInstructionsProps {
  item: IOrderElement;
  isReadOnly?: boolean;
  onRemoveFromCart?: (item: IOrderElement) => void;
}

export const PackingInstructions: React.FC<PackingInstructionsProps> = ({ item, isReadOnly = false, onRemoveFromCart }) => {
  const showTotalRow = item.validity !== StoreItemValidityEnum.MISSING_ITEM;
  const { intl } = usePlatformStoreState();
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const cartOptions = isMobile && Platform.OS === 'web' ? cartScreen?.wrapperMobile : cartScreen?.wrapper;

  return (
    <Box borderBottomWidth={1} borderColor="gray100" {...cartOptions?.items?.separator?.style}>
      <Box flex={1} paddingTop={4} paddingBottom={4} flexDirection="row" testID="packing-instructions">
        <Box marginRight={4} width={70} {...cartOptions?.items?.image?.style} height={0} />
        <Box flex={1} {...(isReadOnly ? { flexDirection: 'row', justifyContent: 'space-between' } : {})}>
          <Text
            marginBottom={1}
            lineHeight={24}
            fontSize="m"
            fontWeight="700"
            color={item.validity === StoreItemValidityEnum.MISSING_ITEM && !isReadOnly ? 'danger' : 'black'}
            {...cartOptions?.items?.title?.style}
          >
            {item.title}
          </Text>

          {showTotalRow && !isReadOnly ? (
            <Text lineHeight={24} fontSize="m" {...cartOptions?.items?.price?.style}>
              {intl.formatNumber(item.quantity * item.price)}
            </Text>
          ) : null}
        </Box>
        {!isReadOnly && onRemoveFromCart ? (
          <Box marginLeft={1}>
            <Button
              accessibilityLabel="Remove item from cart"
              buttonStyle={{
                alignItems: 'flex-start',
              }}
              color="gray400"
              variant="link"
              onPress={() => onRemoveFromCart(item)}
              leftIcon={<CloseIcon stroke="gray900" width={14} height={14} strokeWidth={1.5} />}
            />
          </Box>
        ) : null}
        {isReadOnly && (
          <Box marginLeft={2} alignItems="flex-end">
            <Text lineHeight={24} color="text" fontSize="m" {...cartOptions?.items?.price?.style}>
              {intl.formatNumber(item.quantity * item.price)}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
