import type { PreviewTerminologyDto } from '@goparrot/loyalty-sdk';
import { useGetPointsEarnByOrder } from '@webstore-monorepo/shared/api/loyalty-api';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import * as React from 'react';
import { useEffect, useState } from 'react';

export interface LoyaltyPointsProps {
  orderId: string;
  isAuthUser: boolean;
}

const plurals = (value: number, { plural, singular }: PreviewTerminologyDto) => `${value} ${value === 1 ? singular : plural}`;

export const LoyaltyPoints: React.FC<LoyaltyPointsProps> = ({ orderId, isAuthUser }) => {
  const { storeId } = useWebStore();
  const { dictionary } = useMerchantState();
  const [{ pointsEarned, pointsSpent }, setPoints] = useState({ pointsEarned: 0, pointsSpent: 0 });
  const { refetch: refetchLoyaltyPoints } = useGetPointsEarnByOrder(orderId, storeId, {
    enabled: isAuthUser,
    onSuccess: (stats) => {
      setPoints(stats);
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    if (!isAuthUser) return;

    refetchLoyaltyPoints();
  }, [refetchLoyaltyPoints, isAuthUser, orderId]);

  if (!pointsEarned && !pointsSpent) {
    return null;
  }

  return (
    <Box marginBottom={4} testID="order-complete-loyalty">
      <Box mb={3}>
        <Text fontSize="m" fontWeight="600" lineHeight={24}>
          Loyalty
        </Text>
      </Box>
      <Text lineHeight={24}>
        {pointsEarned ? `${plurals(pointsEarned, dictionary.loyaltyPoints)} earned` : ''}
        {pointsEarned && pointsSpent ? ', ' : ''}
        {pointsSpent ? `${plurals(pointsSpent, dictionary.loyaltyPoints)} spent` : ''}
      </Text>
    </Box>
  );
};
