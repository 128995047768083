import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import type { FC } from 'react';
import React from 'react';

export type FooterProps = {
  isLoading?: boolean;
  onProceed: (isDisabled?: boolean) => void;
  children?: React.ReactNode;
};
export const Footer: FC<FooterProps> = ({ isLoading, onProceed, children }) => {
  const { orderCompleteScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const orderCompleteCustomisation = isMobile ? orderCompleteScreen?.wrapperMobile : orderCompleteScreen?.wrapper;

  const handleCheckout = () => {
    onProceed();
  };

  return (
    <Box flexDirection="column" shadowOffset={{ height: -1, width: 0 }} shadowOpacity={0.1} shadowColor="black">
      <Box>{children}</Box>
      <Box
        height={80}
        paddingTop={4}
        paddingRight={4}
        paddingBottom={4}
        paddingLeft={4}
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        {...orderCompleteCustomisation?.footer?.style}
      >
        <Button
          onPress={handleCheckout}
          isLoading={isLoading}
          {...orderCompleteCustomisation?.footer?.continueButton?.style}
          testID="order-complete-proceed-btn"
        >
          {orderCompleteCustomisation?.footer?.continueButton?.options?.text ?? 'Continue Shopping'}
        </Button>
      </Box>
    </Box>
  );
};
