import type { IStoreItemOptionSelection, IStoreItemOptionWithSelections } from '@goparrot/storeitems-sdk';
import { OpenCollapseModifierGroupEnum } from '@goparrot/webstore-sdk';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useState } from 'react';

import type { SelectionBadgeProps } from '../../../uielements/badges';
import Collapse from '../../../uielements/collapse/Collapse';
import { CollapseHeader } from '../../../uielements/collapse/components/CollapseHeader';
import { SelectionWrapper } from '../../../uielements/selection';
import { AddOnItemMetaData } from '../AddOnItemMetaData';

interface Props {
  itemRef?: any;
  clicked?: boolean;
  property: IStoreItemOptionWithSelections;
  onSelectionsUpdate(updatedSelections: IStoreItemOptionSelection[]): void;
}

export const AddOnItem: React.FC<Props> = ({ itemRef, clicked, property, onSelectionsUpdate }) => {
  const { title, selections_min, subtitle, selections_max, selections: preSelectedSelections } = property;
  const [changed, setChanged] = useState(false);
  const [selections, setSelections] = useState<IStoreItemOptionSelection[]>([]);
  const allSelected = selections.filter((item) => item.selected).length;
  const required = selections_min > 0;
  const webstore = useWebStore();
  const openCollapseModifierGroup = webstore.metadata?.modifiers.openCollapseModifierGroup || OpenCollapseModifierGroupEnum.ALL;
  const isDefaultOpen = {
    [OpenCollapseModifierGroupEnum.ALL]: true,
    [OpenCollapseModifierGroupEnum.NONE]: false,
    [OpenCollapseModifierGroupEnum.REQUIRED]: required,
  };

  const orderedSelections: IStoreItemOptionSelection[] = orderBy(selections, ['show_order', 'validity']);

  const handlePropertyUpdate = (item: IStoreItemOptionSelection) => {
    setChanged(true);
    let updated;
    if (property.selections_min <= 1 && property.selections_max === 1) {
      updated = selections.map((selection) => (selection.uid === item.uid ? { ...item } : { ...selection, selected: false }));
      onSelectionsUpdate(updated);
      return setSelections(updated);
    }
    updated = selections.map((selection) => (selection.uid === item.uid ? { ...item } : selection));
    setSelections(updated);
    onSelectionsUpdate(updated);
  };

  useEffect(() => {
    if (clicked) {
      setChanged(true);
    }
  }, [clicked]);

  useEffect(() => {
    if (preSelectedSelections) {
      setSelections(preSelectedSelections);
    }
  }, [preSelectedSelections]);

  const badgeObj: SelectionBadgeProps = {
    min: selections_min,
    max: selections_max,
    allSelected,
    allSelections: selections.length,
  };

  const collapseTitle = (
    <CollapseHeader
      title={title}
      helperText={subtitle}
      required={required}
      hasError={changed && (allSelected === 0 || (clicked && allSelected < selections_min))}
      badgeObj={badgeObj}
      allSelected={allSelected}
    />
  );

  return (
    <div ref={itemRef} style={{ width: '100%' }}>
      <Collapse title={collapseTitle} defaultOpened={isDefaultOpen[openCollapseModifierGroup]}>
        <SelectionWrapper>
          {orderedSelections?.map((selection, index) => (
            <AddOnItemMetaData
              key={index}
              disabled={
                (selections.length === 1 && selections_min === 1 && selections_max === 1) ||
                (selections_max !== 1 && selections_max === allSelected && !selection.selected)
              }
              selection={selection}
              property={property}
              index={index}
              onSelectionUpdate={handlePropertyUpdate}
            />
          ))}
        </SelectionWrapper>
      </Collapse>
    </div>
  );
};
