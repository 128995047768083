// @ts-ignore
import 'lazysizes/plugins/attrchange/ls.attrchange';
import '../shims/fromentries';
import '../assets/vendors/css/theme/reduced-bootstrap.min.css';
import '../assets/vendors/css/theme/reduced-style.css';
import '../assets/vendors/css/theme/colors/green.css';
import '../assets/styles/general.scss';

import { ComponentsConfigProvider } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { ReactIntlProvider } from '@webstore-monorepo/shared/contexts/react-intl-provider';
import { ReactQueryProvider } from '@webstore-monorepo/shared/contexts/react-query-provider';
import { ThemeProvider } from '@webstore-monorepo/shared/theme';
// @ts-ignore
import lazySizes from 'lazysizes';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';

import { router } from '../routes/routes';
import { ContextWrapper } from '../shared/components/context-wrapper';
import type { SSRContext } from '../shared/interfaces/AppState';
import messages from '../shared/messages';
import { datadogRumInitWrapper } from '../shared/utils/dd-rum';
import { getMergedComponentsConfigurations } from '../theme/merge-configuration';
import { getMergedThemeValues } from '../theme/merge-theme';
import reportWebVitals from './reportWebVitals';

lazySizes.init();
datadogRumInitWrapper();

const polyfills: any[] = [];

if (!window.IntersectionObserver) {
  // @ts-ignore
  polyfills.push(import(/* webpackChunkName: "polyfill-intersection-observer" */ 'intersection-observer'));
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
Promise.all(polyfills)
  .then(() => {
    root.render(
      <React.StrictMode>
        <ThemeProvider theme={getMergedThemeValues()}>
          <ComponentsConfigProvider initial={getMergedComponentsConfigurations()}>
            <ReactQueryProvider>
              <ReactIntlProvider locale="en-US" messages={messages['en-US']}>
                <ContextWrapper appContext={window.SSRContext}>
                  <RouterProvider router={router} />
                </ContextWrapper>
              </ReactIntlProvider>
            </ReactQueryProvider>
          </ComponentsConfigProvider>
        </ThemeProvider>
      </React.StrictMode>,
    );
  })
  .catch((error) => {
    console.error('Failed fetching polyfills', error);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  interface Window {
    baseUrl: string;
    merchantId: string;
    storeId: string;
    soldOutWatermark: string;
    SSRContext: SSRContext;
    hj: any;
    __GP_START_PAGE_LOAD: Date;
    ym: any;
    fbq: any;
    dataLayer: any;
    WEBSTORE_GATEWAY_URL: string;
    DD_RUM_ENABLED: string;
    DD_RUM_CLIENT_TOKEN: string;
    DD_RUM_APPLICATION_ID: string;
    APPLICATION_VERSION: string;
  }
}
