import type { IPaymentTransaction } from '@goparrot/payment-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export const useGetPaymentDetails = (
  transactionId: string,
  options?: UseQueryOptions<IPaymentTransaction, AxiosError, IPaymentTransaction, (QueryKeysEnum | string)[]>,
) => {
  const getDetails = async (): Promise<IPaymentTransaction> => {
    return axios.get(`/api/v1/transactions/${transactionId}`);
  };

  return useQuery([QueryKeysEnum.getPaymentDetails, transactionId], getDetails, options);
};
