import type { WeeklyWorkingSchedule } from '@goparrot/store-v2-sdk';
import type { INestedStoreItemGroup, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { MenuItemCardList } from '@webstore-monorepo/features/menu-item-card-list';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { UiAccessibilityNavigationHelper } from '@webstore-monorepo/ui/accessibility/navigation-helper';
import { Box } from '@webstore-monorepo/ui/box';
import { EmptyFallback } from '@webstore-monorepo/ui/error-fallback';
import { Text } from '@webstore-monorepo/ui/text';
import orderBy from 'lodash/orderBy';
import React, { forwardRef, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { CategoryItemsGroupTitle } from './components/category-items-group-title';

type CategoryItemsGroupProps = {
  id: string;
  title: string;
  subtitle?: string;
  schedule?: WeeklyWorkingSchedule[];
  limit?: number;
  items: (IStoreItemWithOptions | INestedStoreItemGroup)[];
  onItemCardClick: (item: IStoreItemWithOptions | INestedStoreItemGroup) => void;
  onAccNavHelper: (id: string) => void;
};

export const CategoryItemsGroup = forwardRef(
  ({ title, id, subtitle, limit, schedule, items, onItemCardClick, onAccNavHelper }: CategoryItemsGroupProps, forwardedRef) => {
    const { categoryItemsGroup } = useComponentsConfig();
    const { isMobile } = useWindowDimensions();
    const [preparedItems, setPreparedItems] = useState<(IStoreItemWithOptions | INestedStoreItemGroup)[]>([]);

    useEffect(() => {
      if (limit) {
        const itemsCopy = [...items];
        setPreparedItems(itemsCopy.slice(0, limit));
      } else {
        setPreparedItems(items);
      }
    }, [items, limit]);

    if (!preparedItems.length) return null;

    const handleAfterPressEnter = () => {
      onAccNavHelper(id);
    };

    const getShowOrder = (item?: IStoreItemWithOptions | INestedStoreItemGroup) =>
      item?.metadata?.categoryProperties?.find((category) => category.uid === id)?.showOrder;
    const sortedItems = orderBy(preparedItems, getShowOrder);

    return (
      <Box ref={forwardedRef} nativeID={id} marginBottom={isMobile ? 3 : 4} testID="category-items-group">
        <ErrorBoundary FallbackComponent={EmptyFallback}>
          <Box marginBottom={6}>
            {!!schedule && (isMobile ? categoryItemsGroup?.wrapperMobile?.schedule?.options?.text : categoryItemsGroup?.wrapper?.schedule?.options?.text) ? (
              <Box flexDirection="row" alignItems="center" justifyContent="center" flexGrow={1} flexShrink={1}>
                <Box
                  flexGrow={0}
                  flexShrink={1}
                  paddingLeft={2}
                  paddingRight={2}
                  marginBottom={1}
                  {...categoryItemsGroup?.wrapper?.schedule?.style}
                  {...(isMobile ? categoryItemsGroup?.wrapperMobile?.schedule?.style : {})}
                >
                  <Text
                    textAlign="center"
                    paddingLeft={1}
                    paddingRight={1}
                    lineHeight={20}
                    fontSize="xs"
                    {...categoryItemsGroup?.wrapper?.schedule?.text?.style}
                    {...(isMobile ? categoryItemsGroup?.wrapperMobile?.schedule?.text?.style : {})}
                  >
                    {isMobile ? categoryItemsGroup?.wrapperMobile?.schedule?.options?.text : categoryItemsGroup?.wrapper?.schedule?.options?.text}
                  </Text>
                </Box>
              </Box>
            ) : null}
            <CategoryItemsGroupTitle title={title} />
            {subtitle ? (
              <Box
                flexGrow={1}
                flexShrink={0}
                marginTop={4}
                marginBottom={2}
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                {...categoryItemsGroup?.wrapper?.subtitle?.style}
                {...(isMobile ? categoryItemsGroup?.wrapperMobile?.subtitle?.style : {})}
              >
                <Box width="100%">
                  <Text
                    textAlign="center"
                    fontSize="m"
                    {...categoryItemsGroup?.wrapper?.subtitle?.text?.style}
                    {...(isMobile ? categoryItemsGroup?.wrapperMobile?.subtitle?.text?.style : {})}
                  >
                    {subtitle}
                  </Text>
                </Box>
              </Box>
            ) : null}
          </Box>
        </ErrorBoundary>
        <UiAccessibilityNavigationHelper
          accessibilityLabel="navigation helper"
          accessibilityHint="press Enter to return to the category navigation bar"
          onEnter={handleAfterPressEnter}
        >
          Go back to category navigation
        </UiAccessibilityNavigationHelper>
        <MenuItemCardList items={sortedItems} onItemClick={onItemCardClick} />
      </Box>
    );
  },
);
