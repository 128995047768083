import type { IDiningOptionInfo, IOrder, IOrderDelayedInfo, ISlotDetails } from '@goparrot/order-sdk';
import type { IDiningOptionConfig, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { DelayedOrdersUtils, DiningOptionsUtil, ReceiveMethodEnum } from '@goparrot/store-v2-sdk';
import type { ReadWebStoreMetadataDto } from '@goparrot/webstore-sdk';
import { getConvertedOrderPrepTime, hideASAP } from '@webstore-monorepo/shared/utils/cart';

export const getAdditionalFields = (cartDiningOptionsInfo: IDiningOptionInfo, storeDiningOptionsConfig?: IDiningOptionConfig) =>
  Object.keys(cartDiningOptionsInfo.details).reduce((prev: Record<'label' | 'value', any>[], detailsKey): Record<'label' | 'value', any>[] => {
    if (storeDiningOptionsConfig && Object.keys(storeDiningOptionsConfig[cartDiningOptionsInfo.type]?.schema.properties ?? {}).includes(detailsKey)) {
      return [
        ...prev,
        {
          label: storeDiningOptionsConfig[cartDiningOptionsInfo.type]?.schema.properties?.[detailsKey].label,
          value: cartDiningOptionsInfo.details[detailsKey],
        },
      ];
    }
    return [...prev];
  }, []);

export const getDelayedInfoMessage = (
  cart: IOrder,
  metadata: ReadWebStoreMetadataDto,
  store: ReadStoreDto,
  delayedInfo?: IOrderDelayedInfo,
  slot?: ISlotDetails,
) => {
  const convertedPrepTime = getConvertedOrderPrepTime(cart, store);
  if (!hideASAP(cart, store)) {
    return `ASAP${metadata.showASAPOrderPreptime && convertedPrepTime ? ` (~${convertedPrepTime})` : ''}`;
  }
  const isDeliveryRequired = DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo.type);
  const receiveMethod = isDeliveryRequired ? ReceiveMethodEnum.DELIVERY : ReceiveMethodEnum.PICKUP;
  if (!cart.delayedInfo && slot) {
    const availableDays = DelayedOrdersUtils.getAllowedDays(store, receiveMethod);

    const { isAvailable, days = [] } = availableDays;
    return isAvailable ? `${days[0].humanReadable}, ${slot.humanReadable}` : null;
  }
  const orderTime = DelayedOrdersUtils.getFormattedIntervalForSlot(store, receiveMethod, delayedInfo?.date as unknown as string);

  return metadata.hideDelayedOrderTime ? delayedInfo?.humanReadable : `${delayedInfo?.humanReadable}, ${orderTime}`;
};
