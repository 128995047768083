import type { IOrder } from '@goparrot/order-sdk';
import { GiftCardsList, OrderComboList, OrderItem } from '@webstore-monorepo/features/order-items';
import { OrderTotal } from '@webstore-monorepo/features/order-total';
import { useGetApplicableLoyalties } from '@webstore-monorepo/shared/api/loyalty-api';
import { useGetApplicablePromotions } from '@webstore-monorepo/shared/api/promotions-api';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { getCartItems } from '@webstore-monorepo/shared/utils/cart';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import groupBy from 'lodash/groupBy';
import * as React from 'react';

export interface OrderDetailsProps {
  isAuthUser?: boolean;
  lastOrder: IOrder;
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({ isAuthUser, lastOrder }) => {
  const cartItems = getCartItems(lastOrder);
  const { dictionary } = useMerchantState();

  const groupedMultiBrands = groupBy(cartItems, 'menuConceptUuid');

  const { data: promotionsData } = useGetApplicablePromotions({ enabled: isAuthUser });
  const { data: loyalties } = useGetApplicableLoyalties({
    cacheTime: 0,
    enabled: isAuthUser,
  });

  return (
    <Box testID="order-complete-details">
      <Text marginBottom="none" fontSize="xs" fontWeight="500" textTransform="uppercase" mb={4} lineHeight={20}>
        Order Details
      </Text>
      <Box marginBottom={8} backgroundColor="gray100" px={5} py={4}>
        {Object.keys(groupedMultiBrands).map((menuConceptKey) => (
          <Box key={menuConceptKey}>
            {groupedMultiBrands[menuConceptKey].map((item) => (
              <OrderItem
                borderColor="gray200"
                isReadOnly
                item={item}
                key={item.uniqueName}
                promotions={promotionsData?.promotions}
                loyalties={loyalties}
                loyaltyPoints={dictionary.loyaltyPoints}
              />
            ))}
          </Box>
        ))}
        <OrderComboList isEditable={false} lastOrder={lastOrder} borderBottomColor="gray200" />
        {lastOrder?.giftcards?.length ? <GiftCardsList cards={lastOrder.giftcards} isReadOnly /> : null}
        <Box mt={4}>
          <OrderTotal lastOrder={lastOrder} placement="content" showTip page="order-complete" />
        </Box>
      </Box>
    </Box>
  );
};
