import { PaymentMethodTypeEnum } from '@goparrot/common';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import type { IOrder, ReadCartDto } from '@goparrot/order-sdk';
import type { IPaymentSource, IPaymentTransaction, IPaymentTransactionTotalAmount } from '@goparrot/payment-sdk';
import { useGetPaymentDetails } from '@webstore-monorepo/shared/api/transactions-api';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { formatPrice } from '@webstore-monorepo/shared/utils/format-strings';
import { Box } from '@webstore-monorepo/ui/box';
import { Separator } from '@webstore-monorepo/ui/separator';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React from 'react';

const PaymentMethodText: FC<{ sources?: IPaymentSource[]; totalAmounts?: IPaymentTransactionTotalAmount }> = ({ sources, totalAmounts }) => {
  const creditCardsPayments = sources?.filter((source) => source.type === PaymentMethodTypeEnum.CREDIT_CARD);
  const cashPayments = sources?.filter((source) => source.type === PaymentMethodTypeEnum.CASH);
  const applePayments = sources?.filter((source) => source.type === PaymentMethodTypeEnum.APPLE_PAY);
  const googlePayments = sources?.filter((source) => source.type === PaymentMethodTypeEnum.GOOGLE_PAY);

  if (creditCardsPayments?.length) {
    const [{ brand, last4Digits }] = creditCardsPayments;
    const amount = totalAmounts ? `${formatPrice((totalAmounts.tipsAmount + totalAmounts.creditCard) / 100, true)} paid with ` : '';

    return (
      <Text>
        {amount}
        {brand || 'Credit card'}, *${last4Digits}
      </Text>
    );
  }

  if (cashPayments?.length) {
    return <Text>Cash</Text>;
  }

  if (applePayments?.length) {
    const amount = totalAmounts ? `${formatPrice((totalAmounts.tipsAmount + totalAmounts.applePay) / 100, true)} paid with ` : '';

    return <Text>{amount}Apple Pay</Text>;
  }

  if (googlePayments?.length) {
    const amount = totalAmounts ? `${formatPrice((totalAmounts.tipsAmount + totalAmounts.googlePay) / 100, true)} paid with ` : '';

    return <Text>{amount}Google Pay</Text>;
  }

  return null;
};

export interface PaymentDetailsProps {
  lastOrder: IOrder;
}
export const PaymentDetails: React.FC<PaymentDetailsProps> = ({ lastOrder }) => {
  const { paymentInformation, _id } = lastOrder;
  const { user } = useUserContextState();
  const { data: paymentDetails } = useGetPaymentDetails(paymentInformation.uid!, {
    enabled: _id && user.type === UserTypeEnum.AUTHENTICATED,
    // the type is actually (lastOrder as ReadCartDto & { transaction?: Omit<IPaymentTransaction, 'details'>})
    initialData: (lastOrder as ReadCartDto & { transaction?: IPaymentTransaction }).transaction,
  });
  const { sources, totalAmounts } = paymentDetails || {};
  const giftcardPayments = sources?.filter((source) => source.type === PaymentMethodTypeEnum.GIFT_CARD);
  const giftCardsEndings = giftcardPayments?.map((giftCardPayment) => `*${giftCardPayment.last4Digits}`).join(', ');

  return (
    <Box>
      <Text marginBottom="none" fontSize="xs" fontWeight="500" textTransform="uppercase" lineHeight={20}>
        Payment Details
      </Text>
      <Box mt={4}>
        <Text fontSize="m" fontWeight="600" lineHeight={24} paddingBottom={2}>
          Payment method
        </Text>
        {paymentDetails ? <PaymentMethodText totalAmounts={totalAmounts} sources={sources} /> : null}
      </Box>
      <Separator mt={4} mb={4} />
      {giftcardPayments?.length && totalAmounts && totalAmounts.giftCard > 0 ? (
        <>
          {' '}
          <Box>
            <Text fontSize="m" fontWeight="600" lineHeight={24} paddingBottom={2}>
              Gift cards
            </Text>
            <Text>
              {formatPrice(totalAmounts.giftCard / 100)} paid, {giftCardsEndings}
            </Text>
          </Box>
          <Separator mb={4} mt={4} />
        </>
      ) : null}
    </Box>
  );
};
