import styled, { css } from 'styled-components';

import { media } from '../../shared/utils/media-queries';

export const Container = styled.div<{ isMinimized: boolean; customStyles: any }>`
  ${({ customStyles, isMinimized }) => css`
    position: fixed;
    right: 16px;
    left: 16px;
    bottom: 16px;
    background: white;
    z-index: 99;

    ${media.tablet} {
      width: 343px;
      left: unset;
    }

    .wrapper {
      width: 100%;
    }

    .order-summary {
      padding: 16px;
      display: grid;
      grid-template-columns: auto min-content;
      align-items: center;
      background: ${customStyles?.summaryContainer?.background};

      &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        display: flex;
        margin-right: 24px;
        align-items: center;
        color: var(--main-color);
        ${css(customStyles?.summaryContainer?.title ?? {})};

        .clock-icon {
          margin-top: 2px;
          margin-left: 4px;

          circle,
          path {
            stroke: var(--main-color);
            stroke: ${customStyles?.summaryContainer?.title?.color};
          }
        }
      }

      &__price {
        font-size: 20px;
        font-weight: 700;
        justify-self: self-end;
        color: var(--main-color);
        ${css(customStyles?.summaryContainer?.title ?? {})};
      }

      &__details {
        margin-top: 4px;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        grid-column: 1 / 3;
        ${css(customStyles?.summaryContainer?.text ?? {})};
      }

      &__action {
        .button {
          height: 28px;
          padding: 0 25px;
        }
      }
    }

    .actions__container {
      background: #f3f4f6;
      background: ${customStyles?.actionsContainer?.background};
      padding: 12px 16px 16px 16px;
    }

    .actions {
      display: flex;

      .button {
        padding: 0;
        height: 40px;
        width: 100%;

        &:first-child {
          margin-right: 6px;
        }
        &:last-child {
          margin-left: 6px;
        }
        &:first-child:last-child {
          margin: 0;
        }
      }
    }

    .note {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 12px;
      color: #64748b;
      ${css(customStyles?.actionsContainer?.text ?? {})};
    }

    ${isMinimized &&
    css`
      border-radius: 0;
      height: 48px;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      ${media.tablet} {
        width: 100%;
      }

      .wrapper {
        width: 100%;
        padding: 0 16px;

        ${media.tablet} {
          width: auto;
          margin: 0 auto;
          padding: 0;
        }
      }

      .order-summary {
        padding: 0;
      }

      .order-summary {
        background: white;

        &--title {
          margin-right: 25px;
          font-size: 18px;
        }
      }
    `}
  `}
`;
