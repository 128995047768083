import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import * as React from 'react';

export interface OrderNumberProps {
  orderNumber: string;
}
export const OrderNumber: React.FC<OrderNumberProps> = ({ orderNumber }) => {
  return (
    <Box marginBottom={4} testID="order-complete-number">
      <Box mb={3}>
        <Text fontSize="m" fontWeight="600" lineHeight={24}>
          Order number
        </Text>
      </Box>
      <Text lineHeight={24}>{orderNumber}</Text>
    </Box>
  );
};
