import { UserTypeEnum } from '@goparrot/customer-sdk';
import { DelayedOrdersUtils } from '@goparrot/store-v2-sdk';
import type { INestedStoreItemGroup, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { HeroBlock } from '@webstore-monorepo/features/hero-block';
import { MenuConceptsBar } from '@webstore-monorepo/features/menu-concepts';
import { MenuItemsSearchResult } from '@webstore-monorepo/features/menu-items-search-result';
import { OrderSetupStatusBar } from '@webstore-monorepo/features/order-setup-status-bar';
import { OrderToTableBar } from '@webstore-monorepo/features/order-to-table';
import { PromotionsBar } from '@webstore-monorepo/features/promotions-bar';
import { useGetMenuConcepts, useWebStoreItems } from '@webstore-monorepo/shared/api/cart-api';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import type { UserState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { useUserContextState, useUserContextStateDispatch } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { globalCache } from '@webstore-monorepo/shared/utils/global-cache';
import { LocalStorage } from '@webstore-monorepo/shared/utils/local-storage';
import { parseURLSearchParams } from '@webstore-monorepo/shared/utils/url';
import { EmptyFallback } from '@webstore-monorepo/ui/error-fallback';
import type { RefObject } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

import { CategoryNavBar } from '../../components/category-nav-bar';
import { OrderReminderBanner } from '../../components/order-reminder-banner';
import { APP_PATHS } from '../../routes/paths';
import { INIT_PROMO_COOKIES, PROMOTION_COOKIE } from '../../shared/constants/promotions';
import { useAppContextDispatch, useAppContextState } from '../../shared/contexts/app-context-provider';
import { useNotification } from '../../shared/contexts/notification-provider';
import { useAnalytics } from '../../shared/hooks/use-analytics';
import { useAppHistory } from '../../shared/hooks/use-app-history';
import { useShowOrderSetup } from '../../shared/hooks/use-show-order-setup';
import { useStoreStateItemUtils } from '../../shared/hooks/use-store-item-utils';
import type { CookiePromosProps, PromotionExtended } from '../../shared/interfaces/Promotions';
import { LocalStorageKeysEnum } from '../../shared/services/v2';
import { getIntlString } from '../../shared/utils/intl';
import { getOrderToTableInfo } from '../../shared/utils/order-to-table-helper';
import { MENU_PATHS } from '../paths';
import { MenuList, PromoPopup } from './components';
import * as s from './Menu.styled';

const localStorage = new LocalStorage();

export const Menu: React.FC = () => {
  const appState = useAppContextState();
  const appStateDispatch = useAppContextDispatch();
  const store = useStoreState();
  const cart = useCartState();
  const { user } = useUserContextState();
  const { updateUserContext } = useUserContextStateDispatch();
  const notification = useNotification();
  const showOrderSetup = useShowOrderSetup();
  const [showOrderStatusBanner, setShowOrderStatusBanner] = useState(false);
  const headerContainer = appState?.headerElementHeight ?? 0;
  const renderedOnce = useRef(false);
  const location = useLocation();
  const history = useAppHistory();
  const searhParams = parseURLSearchParams();
  const analytics = useAnalytics();
  const [searchStr, setSearchStr] = useState('');
  const stickyContainerRef = useRef<HTMLDivElement>();
  const categoryNavBarContainer = useRef<HTMLDivElement>(null);
  const menuListCategoryItemsRef = useRef<RefObject<HTMLDivElement>[]>([]);
  const { selectedMenuConceptUniqueNames } = appState;
  const { data: webstoreItems } = useWebStoreItems({ selectedMenuConceptUniqueNames }, { keepPreviousData: true });
  const hasPromotions = !!appState.promotions?.length;
  const [placeholderRemoved, setPlaceholderRemoved] = useState(false);
  const { handleShowItemNew } = useStoreStateItemUtils();
  const [showSearchResults, setShowSearchResults] = useState(false);
  const { isFetched: isMenuConceptsFetched } = useGetMenuConcepts({
    onSuccess: (data) => {
      if (data.length > 0) {
        const menuConceptToSelect = (searhParams?.concept && data.find((concept) => concept?.title === searhParams?.concept)) ?? data[0];
        appStateDispatch({ type: 'update', payload: { selectedMenuConceptUniqueNames: [menuConceptToSelect.uniqueName] } });
      }
    },
  });
  const allItems = [...(webstoreItems?.storeItems ? webstoreItems.storeItems : []), ...(webstoreItems?.storeItemBundles ? webstoreItems.storeItemBundles : [])];

  const handlePlaceholderRemoved = useCallback(() => {
    setPlaceholderRemoved(true);
  }, []);

  const handleScrollToCategory = useCallback(
    (index: number) => {
      const height = (stickyContainerRef?.current?.clientHeight ?? 0) + (categoryNavBarContainer?.current?.clientHeight ?? 0) + (headerContainer ?? 0);

      if (index === -1) {
        window.scrollTo({ top: (appState?.topSellingItemsSliderOffsetTop ?? 0) - height });
        return;
      }
      if (placeholderRemoved) {
        window.scrollTo({ top: (menuListCategoryItemsRef.current?.[index]?.current?.offsetTop ?? 0) - height });
      }
    },
    [appState?.topSellingItemsSliderOffsetTop, headerContainer, placeholderRemoved],
  );

  const handleOnItemCardClick = (item: IStoreItemWithOptions | INestedStoreItemGroup) => {
    handleShowItemNew({ item });
  };

  const handleAfterConfirmAlert = (data: { isOrderToTable: boolean }) => {
    appStateDispatch({ type: 'update', payload: { orderToTableInfo: data } });
    if (!appState.isDynamicMenuEnabled) {
      history.push(APP_PATHS.home);
    }
  };

  const handleAfterUpdateCartActions = () => {
    localStorage.set(LocalStorageKeysEnum.IS_DINING_OPTION_SELECTED_BY_USER, String(true));
  };

  useEffect(() => {
    if (user?.isFirstLogin && appState.loyaltyBase?.pointsAvailable) {
      notification.success(
        getIntlString('loyalty.registration.message', { amount: appState.loyaltyBase?.pointsAvailable, points: appState.loyaltyPointName?.plural }),
      );
      updateUserContext({ type: 'update', payload: { user: { ...user, isFirstLogin: false } as UserState } });
    }
  }, [appState.loyaltyBase?.pointsAvailable, appState.loyaltyPointName?.plural, user.isFirstLogin, notification]);

  useEffect(() => {
    const searchParams = parseURLSearchParams();

    if (searchParams.address) {
      const addressFromUrl = decodeURIComponent(searchParams.address);

      try {
        globalCache.locationSelectorAddress = JSON.parse(addressFromUrl);
      } catch (error) {
        analytics.track('url_address_parse_error', { addressFromUrl });
      } finally {
        const params = new URLSearchParams(location.search);
        params.delete('address');
        history.replace(`${location.pathname}?${params.toString()}`);
      }
    }

    if (searchParams.pickup) {
      globalCache.locationSelectorIsPickup = true;
      history.replace(location.pathname);
    }

    if (
      !renderedOnce.current &&
      placeholderRemoved &&
      searchParams.category &&
      webstoreItems?.storeItemsByCategory?.length &&
      webstoreItems?.storeItemsByCategory?.length > 0
    ) {
      const foundIndex = webstoreItems?.storeItemsByCategory.findIndex((item) => item.title.trim() === decodeURI(searchParams.category?.trim()));
      renderedOnce.current = true;
      handleScrollToCategory(foundIndex);
    }
  }, [placeholderRemoved, webstoreItems?.storeItemsByCategory, handleScrollToCategory, location]);

  useEffect(() => {
    if (stickyContainerRef.current) {
      stickyContainerRef.current.style.top = `${headerContainer - 1}px`;
    }
  }, [headerContainer]);

  useEffect(() => {
    const orderAheadAvailabilityForMethods = DelayedOrdersUtils.isOrderAheadAvailable(store);
    const someOrderAheadAvailable = Object.values(orderAheadAvailabilityForMethods).includes(true);
    setShowOrderStatusBanner(!!(appState.isDynamicMenuEnabled && someOrderAheadAvailable && !appState.orderToTableInfo?.isOrderToTable));
    appStateDispatch({ type: 'update', payload: { someOrderAheadAvailable } });
  }, [appState.isDynamicMenuEnabled, appState.orderToTableInfo?.isOrderToTable, appStateDispatch, store]);

  useEffect(() => {
    appStateDispatch({
      type: 'update',
      payload: { orderToTableInfo: getOrderToTableInfo({ store, availableDiningOptions: store.featuresFlags?.availableDiningOptions }, cart) },
    });
  }, [user]);

  useEffect(() => {
    showOrderSetup();
  }, [showOrderSetup]);

  const handleHidePromotions = (promotion: PromotionExtended, idx: number) => {
    const cookies = new Cookies();
    const cookiesPromotions: CookiePromosProps = cookies.get(PROMOTION_COOKIE) || INIT_PROMO_COOKIES;

    cookiesPromotions.hidden[promotion.uuid] = true;
    cookies.set(PROMOTION_COOKIE, cookiesPromotions);

    appStateDispatch({
      type: 'update',
      payload: { promotions: appState.promotions?.map((item, index) => (index === idx ? { ...item, promotion: { ...item.promotion, hidden: true } } : item)) },
    });
  };

  const promoBarList = React.useMemo(() => {
    if (appState.promotions) {
      const cookies = new Cookies();
      const cookiePromotions: CookiePromosProps | undefined = cookies.get(PROMOTION_COOKIE);

      return appState.promotions.filter((promoItem) => !cookiePromotions?.hidden?.[promoItem.promotion.uuid]);
    }

    return [];
  }, [appState.promotions]);

  const handleOrderToTableRefChange = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        appStateDispatch({ type: 'update', payload: { orderToTableBarElementHeight: node.getBoundingClientRect().height } });
      }
    },
    [appStateDispatch],
  );
  const handleStickyContainerRefChange = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        stickyContainerRef.current = node;
        appStateDispatch({ type: 'update', payload: { stickyContainerHeight: node.getBoundingClientRect().height } });
      }
    },
    [appStateDispatch],
  );

  const handleChangeOrderSetup = () => {
    history.push(MENU_PATHS.order_setup);
  };

  const handleSelectMenuConcept = (uniqueName: string, title: string) => {
    setPlaceholderRemoved(false);
    appStateDispatch({ type: 'update', payload: { selectedMenuConceptUniqueNames: [uniqueName] } });
    history.push(`${APP_PATHS.home}?concept=${encodeURIComponent(title)}`);
  };

  const handleCloseSearch = () => {
    setSearchStr('');
    setShowSearchResults(false);
  };

  const handleSearchMenuItems = (searchStr: string) => {
    setSearchStr(searchStr);
    setShowSearchResults(searchStr.length >= 3);
  };

  const handleAccNavHelperPressed = (categoryId: string) => {
    if (menuListCategoryItemsRef.current) {
      document.getElementById(`navbar-category-item-${categoryId}`)?.focus();
    }
  };

  return (
    <>
      <s.Container>
        <ErrorBoundary FallbackComponent={EmptyFallback}>
          <s.StickyContainer ref={handleStickyContainerRefChange}>
            <OrderToTableBar
              isRemovable
              ref={handleOrderToTableRefChange}
              orderToTableInfo={appState.orderToTableInfo}
              onAfterConfirmAlert={handleAfterConfirmAlert}
              onAfterUpdateCartActions={handleAfterUpdateCartActions}
            />
          </s.StickyContainer>
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={EmptyFallback}>
          <HeroBlock />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={EmptyFallback}>
          {isMenuConceptsFetched ? (
            <MenuConceptsBar selectedMenuConcept={selectedMenuConceptUniqueNames?.[0] ?? ''} onSelectMenuConcept={handleSelectMenuConcept} />
          ) : null}
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={EmptyFallback}>
          <CategoryNavBar
            key={selectedMenuConceptUniqueNames?.[0] ?? 0}
            placeholderRemoved={placeholderRemoved}
            innerRef={categoryNavBarContainer}
            stickyHeaderHeight={stickyContainerRef?.current?.clientHeight ?? 0}
            onScrollToCategory={handleScrollToCategory}
            menuListCategoryItemsRef={menuListCategoryItemsRef}
            onSearchChange={handleSearchMenuItems}
            onCloseSearch={handleCloseSearch}
          />
        </ErrorBoundary>
        {!appState.hidePromotionBar && hasPromotions && <PromotionsBar promotionList={promoBarList} onClosePromotion={handleHidePromotions} />}

        <ErrorBoundary FallbackComponent={EmptyFallback}>
          {showOrderStatusBanner && <OrderSetupStatusBar onChangeOrderSetup={handleChangeOrderSetup} />}
        </ErrorBoundary>
        {showSearchResults ? <MenuItemsSearchResult searchStr={searchStr} items={allItems} onMenuItemClick={handleOnItemCardClick} /> : null}
        <div style={{ display: showSearchResults ? 'none' : 'block' }}>
          <MenuList
            menuListCategoryItemsRef={menuListCategoryItemsRef}
            onAccNavHelperPressed={handleAccNavHelperPressed}
            onPlaceholderRemoved={handlePlaceholderRemoved}
          />
        </div>
        {hasPromotions && <PromoPopup />}
        {user?.type === UserTypeEnum.AUTHENTICATED ? <OrderReminderBanner /> : null}
      </s.Container>
      <Outlet />
    </>
  );
};
