import { GiftCardProviderEnum } from '@goparrot/common';
import type { ReadGiftCardDto } from '@goparrot/giftcards-sdk';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { useTheme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Input } from '@webstore-monorepo/ui/input';
import { Text } from '@webstore-monorepo/ui/text';
import type { FormikErrors } from 'formik';
import { useFormik } from 'formik';
import type { FC } from 'react';
import React from 'react';
import { Modal, Pressable } from 'react-native';
import * as Yup from 'yup';

import { useAttachGiftCard } from '../../api/mutations/use-gift-card';
import { useGiftCardDispatch } from '../../contexts/gift-card-provider';

type AddNewGiftCardType = {
  isVisible: boolean;
  onClose: () => void;
};

export const AddNewGiftCard: FC<AddNewGiftCardType> = ({ isVisible, onClose }) => {
  const { notification, analytics } = usePlatformStoreState();
  const { height, isMobile } = useWindowDimensions();
  const store = useStoreState();
  const { onAddNewGiftCard } = useGiftCardDispatch();
  const { mutate: onAttachGiftCards, isLoading } = useAttachGiftCard({
    onSuccess: (newGiftCard: ReadGiftCardDto) => {
      onAddNewGiftCard(newGiftCard, newGiftCard.provider.gan?.substring(12));
      analytics.track('gift_card_attach_success', { newGiftCard });
      onClose();
    },
    onError: (error: any) => {
      let errorMessage;
      if ([404, 422].includes(error.response?.status)) {
        errorMessage = 'Gift Card with such number was not found';
      }
      if (!errorMessage) errorMessage = error.response?.data.message || error.message;
      if (Array.isArray(errorMessage)) errorMessage = errorMessage[0].message;
      notification.error(errorMessage);
      analytics.track('gift_card_attach_error', { error: errorMessage });
    },
  });
  const theme = useTheme<Theme>();
  const parentVerticalPadding = 6;
  const buttonsMarginBottom = 6;
  const parentVerticalPaddingValue = theme.spacing[parentVerticalPadding];
  const buttonsMarginBottomValue = theme.spacing[buttonsMarginBottom];
  const mainContentHeight = isMobile ? height - 48 - parentVerticalPaddingValue * 2 - buttonsMarginBottomValue : 'auto';

  const handleSave = async () => {
    try {
      const sanitizedGan = values.giftCard.replace(/[^a-z0-9]/gi, '');
      const errors: FormikErrors<{ giftCard: string }> = await validateForm({
        giftCard: sanitizedGan,
      });

      setFieldError('giftCard', errors.giftCard);

      if (Object.values(errors).length) {
        return;
      }

      resetForm();
      onAttachGiftCards({ gan: sanitizedGan ?? '', provider: GiftCardProviderEnum.SQUARE, storeId: store?.storeId ?? '' });
    } catch (e: any) {
      notification.error(e.message);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleGiftCardChange = (value: string) => {
    handleChange('giftCard')(value);
  };

  const { handleChange, touched, validateForm, setFieldError, resetForm, errors, values, handleSubmit } = useFormik({
    initialValues: { giftCard: '' },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      giftCard: Yup.string()
        .required('Enter your gift card number.')
        .min(3, 'Must be more than 3 characters')
        .max(25, 'Must be less than 25 characters')
        .matches(/^[a-zA-Z0-9](?:\s?[a-zA-Z0-9])*$/, 'Please enter a valid gift card number'),
    }),
    onSubmit: handleSave,
  });

  return (
    <Modal animationType="fade" transparent onRequestClose={handleClose} visible={isVisible}>
      <Box testID="add-new-gift-card" flexDirection="row" justifyContent="center" minHeight="100%">
        <Pressable onPress={handleClose} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Box backgroundColor="modalBackdrop" position="absolute" top={0} bottom={0} left={0} right={0} />
        </Pressable>
        <Box
          backgroundColor="white"
          flexGrow={1}
          minHeight={500}
          maxWidth={isMobile ? '100%' : 600}
          marginTop="none"
          marginBottom="none"
          borderRadius={isMobile ? 'none' : 'lg'}
          paddingTop={parentVerticalPadding}
          paddingBottom={parentVerticalPadding}
          paddingLeft={4}
          paddingRight={4}
          style={{
            marginTop: isMobile ? 0 : 'auto',
            marginBottom: isMobile ? 0 : 'auto',
          }}
        >
          <Box px={4}>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <Button testID="modal-close-button" size="xxs" backgroundColor="gray100" onPress={onClose}>
                <CloseIcon stroke="black" fill="black" width={14} height={14} strokeWidth={1.5} />
              </Button>
              <Button isDisabled={isLoading} onPress={handleSubmit} buttonStyle={{ minWidth: 70 }} testID="gift-card-save-button">
                Save
              </Button>
            </Box>
          </Box>
          <Box height={mainContentHeight} overflow="scroll" px={4}>
            <Box py={6}>
              <Text fontSize="xxl" lineHeight={32} fontWeight="700">
                Add new gift card
              </Text>
            </Box>
            <Box>
              <Input
                value={values.giftCard}
                // @ts-ignore
                onChangeText={handleGiftCardChange}
                inputState={{ touched: !!touched.giftCard, error: !!errors.giftCard }}
                errorMessage={errors.giftCard}
                placeholder="Ex.: 1234 2345 3456 4567"
                testID="gift-card-add-input"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
