import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

export type ContentProps = {
  boxProps?: BoxProps<Theme> & React.RefAttributes<unknown>;
  children?: React.ReactNode;
};
export const Content: FC<PropsWithChildren<ContentProps>> = ({ boxProps, children }) => {
  const { orderCompleteScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const options = isMobile ? orderCompleteScreen?.wrapperMobile : orderCompleteScreen?.wrapper;

  return (
    <Box paddingBottom={5} paddingTop={5} paddingLeft={4} paddingRight={4} {...options?.content?.style} {...boxProps}>
      {children}
    </Box>
  );
};
