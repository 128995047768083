import { DiningOptionEnum } from '@goparrot/common';
import type { IFontStylesApp, WebMerchantComponentsType } from '@goparrot/webstore-sdk';
import type { BoxProps } from '@shopify/restyle';
import { useGetFirstSlotForTheDay } from '@webstore-monorepo/shared/api/cart-api';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { ClockIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { hideASAP } from '@webstore-monorepo/shared/utils/cart';
import { getInvalidMessageForDefaultDiningOption, orderAsapReceiveTimeInfo } from '@webstore-monorepo/shared/utils/dining-options';
import { getOrderAheadAvailable } from '@webstore-monorepo/shared/utils/store';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React from 'react';

import { getDelayedInfoMessage } from '../utils';

type SelectedDelayedInfoProps = {
  iconBoxStyleProps?: BoxProps<Theme>;
  textStyleProps?: WebMerchantComponentsType<IFontStylesApp>;
  isReadOnly?: boolean;
};
export const SelectedDelayedInfo: FC<SelectedDelayedInfoProps> = ({ iconBoxStyleProps, textStyleProps, isReadOnly }) => {
  const cart = useCartState();
  const diningOptionType = cart.diningOptionInfo.type;
  const webstore = useWebStore();
  const store = useStoreState();
  const { isMobile } = useWindowDimensions();
  const { delayedInfo } = cart || {};
  const { data: slot, isLoading } = useGetFirstSlotForTheDay();
  const delayedInfoMessage =
    getOrderAheadAvailable(store, diningOptionType) && slot
      ? getDelayedInfoMessage(cart, webstore.metadata, store, delayedInfo, slot)
      : orderAsapReceiveTimeInfo(cart, store, !!webstore.metadata.hideDelayedOrderTime);
  const { showError, delayedInfoText } = getInvalidMessageForDefaultDiningOption(cart, store);
  const { orderConfirmationScreen } = useComponentsConfig();
  const diningOptionsConfig = isMobile ? orderConfirmationScreen?.wrapperMobile?.diningOptions : orderConfirmationScreen?.wrapper?.diningOptions;
  const isDelayedOrder = hideASAP(cart, store);
  const deliveryMessage =
    diningOptionType === DiningOptionEnum.DELIVERY ? diningOptionsConfig?.options?.deliveryText : diningOptionsConfig?.options?.pickupText;
  const replacedTime = (message: string, time: string) => {
    const placeholderPosition = message?.indexOf('%time%');
    if (placeholderPosition < 0) {
      return message;
    }
    return (
      <>
        {message?.substring(0, placeholderPosition)}
        <Text fontWeight="600">{time}</Text>
        {message?.substring(placeholderPosition + 6)}
      </>
    );
  };

  const readonlyDeliveryMessage = () => {
    if (!delayedInfoMessage || !deliveryMessage) return null;

    const orderAsapReceiveTime = orderAsapReceiveTimeInfo(cart, store, !!webstore.metadata.hideDelayedOrderTime);

    return (
      <Text lineHeight={16} fontWeight="500" fontSize="s" {...textStyleProps}>
        {replacedTime(deliveryMessage, isDelayedOrder ? delayedInfoMessage : orderAsapReceiveTime)}
      </Text>
    );
  };

  return !isLoading ? (
    <>
      <Box marginRight={1} {...iconBoxStyleProps}>
        <ClockIcon height={24} width={24} testID="order-setup-status-dateTime-clock-icon" stroke={textStyleProps?.color} fill="transparent" />
      </Box>
      {isReadOnly && deliveryMessage ? (
        readonlyDeliveryMessage()
      ) : (
        <Text lineHeight={16} fontWeight="600" fontSize="s" {...textStyleProps}>
          {showError && !!delayedInfoText ? <Text color="gray300">{delayedInfoText}</Text> : delayedInfoMessage}
        </Text>
      )}
    </>
  ) : null;
};
