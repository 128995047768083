import { DiningOptionEnum } from '@goparrot/common';
import type { IOrder } from '@goparrot/order-sdk';
import { DiningOptionsUtil } from '@goparrot/store-v2-sdk';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import * as React from 'react';

export interface DeliveryMethodProps {
  lastOrder: IOrder;
}
export const DeliveryMethod: React.FC<DeliveryMethodProps> = ({ lastOrder }) => {
  const {
    diningOptionInfo: { type: diningOptionType, details },
  } = lastOrder;
  const store = useStoreState();

  const renderFormattedAddress: () => string = () => {
    if (diningOptionType === DiningOptionEnum.DINE_IN) {
      return 'Table ' + details?.tableNumber;
    }

    if (diningOptionType === DiningOptionEnum.CURBSIDE || diningOptionType === DiningOptionEnum.TAKE_OUT) {
      return DiningOptionsUtil.getHumanReadableName(diningOptionType, store) + ' from ' + store.name + ' at ' + store?.location?.formattedAddress;
    }

    if (diningOptionType === DiningOptionEnum.DELIVERY) {
      return 'Delivery to ' + details?.address?.formattedAddress;
    }

    return DiningOptionsUtil.getHumanReadableName(diningOptionType, store) + ' to ' + details?.address?.formattedAddress;
  };

  return (
    <Box marginBottom={8} testID="order-complete-delivery-method">
      <Box mb={3}>
        <Text fontSize="m" fontWeight="600" lineHeight={24}>
          Delivery method
        </Text>
      </Box>
      <Text lineHeight={24}>{renderFormattedAddress()}</Text>
    </Box>
  );
};
