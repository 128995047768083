import { DiningOptionEnum } from '@goparrot/common';
import type { IOrder } from '@goparrot/order-sdk';
import { getFulfillmentMinutes, OrderDelayedInfoUtil } from '@goparrot/order-sdk';
import { DiningOptionsUtil } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { orderAsapReceiveTimeInfo } from '@webstore-monorepo/shared/utils/dining-options';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import * as React from 'react';
import { useEffect, useState } from 'react';

export interface OrderStatusProps {
  lastOrder: IOrder;
}
export const OrderStatus: React.FC<OrderStatusProps> = ({ lastOrder }) => {
  const webstore = useWebStore();
  const { metadata } = webstore;
  const store = useStoreState();
  const isDelayedOrder = !OrderDelayedInfoUtil.isASAP(lastOrder);
  const { orderCompleteScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const contentOptions = isMobile ? orderCompleteScreen?.wrapperMobile?.content?.options : orderCompleteScreen?.wrapper?.content?.options;
  const [orderAsapReceiveTime, setOrderAsapReceiveTime] = useState('');
  const deliveryMessage = contentOptions?.deliveryMessage ?? metadata.customMessages?.orderConfirmation?.orderComplete?.delivery ?? null;
  const pickupMessage = contentOptions?.pickupMessage ?? metadata.customMessages?.orderConfirmation?.orderComplete?.pickup ?? null;
  const isDeliveryRequired = lastOrder && DiningOptionsUtil.isDeliveryRequired(lastOrder.diningOptionInfo.type);
  const { preparedIn: pickupTime, fulfilledIn: deliveryTime } = getFulfillmentMinutes(lastOrder?.schedulingInfo, {
    pickupTimeDelta: store.pickup?.time,
    deliveryTimeDelta: store.delivery?.time,
  });
  const replacedTime = (message: string, time: string) => {
    const chunks = message.split('%time%');
    if (chunks.length === 2) {
      return (
        <>
          {chunks[0]}
          <Text fontWeight="bold">{time}</Text>
          {chunks[1]}
        </>
      );
    }
    return message;
  };

  useEffect(() => {
    if (lastOrder) {
      setOrderAsapReceiveTime(orderAsapReceiveTimeInfo(lastOrder, store, !!metadata.hideDelayedOrderTime));
    }
  }, [lastOrder, store, metadata.hideDelayedOrderTime]);

  const getDeliveryMessage = () => {
    return !isDelayedOrder ? (
      <>
        {deliveryMessage ? replacedTime(deliveryMessage, orderAsapReceiveTime) : null}
        {!deliveryMessage && deliveryTime ? (
          <>
            Your order will be ready on <Text fontWeight="bold">{orderAsapReceiveTime}</Text>
          </>
        ) : null}
      </>
    ) : (
      <>
        {deliveryMessage ? replacedTime(deliveryMessage, orderAsapReceiveTime) : null}
        {!deliveryMessage && deliveryTime ? (
          <>
            Your order is scheduled for delivery on <Text fontWeight="bold">{orderAsapReceiveTime}</Text>
          </>
        ) : null}
      </>
    );
  };

  const getPickupMessage = () => {
    return isDelayedOrder ? (
      <>
        {pickupMessage ? replacedTime(pickupMessage, orderAsapReceiveTime) : null}
        {!pickupMessage && pickupTime ? (
          <>
            Your order is scheduled for pickup on <Text fontWeight="bold">{orderAsapReceiveTime}</Text>
          </>
        ) : null}
      </>
    ) : (
      <>
        {pickupMessage ? replacedTime(pickupMessage, orderAsapReceiveTime) : null}
        {!pickupMessage && pickupTime ? (
          <>
            Your order will be ready on <Text fontWeight="bold">{orderAsapReceiveTime}</Text>
          </>
        ) : null}
      </>
    );
  };

  const deliveryInformationMessage = () => {
    if (lastOrder.diningOptionInfo.type === DiningOptionEnum.DINE_IN) {
      return `Give us about ${lastOrder.schedulingInfo?.prepTime} minutes to prepare your food and bring it to your table.`;
    }

    if (isDeliveryRequired) {
      return getDeliveryMessage();
    } else {
      return getPickupMessage();
    }
  };

  return (
    <Box mb={8} testID="order-complete-status">
      <Box mb={2}>
        <Text fontSize="xs" textTransform="uppercase" lineHeight={20}>
          Order status
        </Text>
      </Box>
      <Box>
        <Text fontSize="m" lineHeight={24}>
          {deliveryInformationMessage()}
        </Text>
      </Box>
    </Box>
  );
};
