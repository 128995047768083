import { onKeyEnterAndSpace } from '@webstore-monorepo/shared/utils/forms';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { CloseIcon } from '../icons/CloseIcon';
import * as s from './Popup.style';

interface Props {
  show: boolean;
  onClose(): void;
  children(): React.ReactNode;
}

export const Popup: React.FC<Props> = ({ show, onClose, children }) => {
  const nodeRef = React.useRef(null);

  const handleKeyUp = (event: any) => {
    onKeyEnterAndSpace(event, onClose);
  };

  return (
    // @see nodeRef https://github.com/reactjs/react-transition-group/blob/master/CHANGELOG.md#440-2020-05-05
    <CSSTransition nodeRef={nodeRef} unmountOnExit in={show} timeout={200} classNames="popup">
      <s.Container tabIndex={-1}>
        <div role="button" aria-label="close popup" tabIndex={0} onKeyUp={handleKeyUp} onClick={onClose}>
          <CloseIcon className="close-icon" />
        </div>
        {show && children()}
      </s.Container>
    </CSSTransition>
  );
};
