import { DiningOptionEnum } from '@goparrot/common';
import type { ICartAction } from '@goparrot/order-sdk';
import { CartActionTypeEnum } from '@goparrot/order-sdk';
import { useCartHandleActions } from '@webstore-monorepo/shared/api/cart-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { Input } from '@webstore-monorepo/ui/input';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';

export const OrderNotesInput: React.FC = () => {
  const { analytics } = usePlatformStoreState();
  const cart = useCartState();
  const cartDispatch = useCartDispatch();
  const webstore = useWebStore();
  const { mutate: handleCartActions } = useCartHandleActions({
    onSuccess: (cart) => {
      cartDispatch({ type: 'update', payload: cart });
    },
  });
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const diningOptionsNotesConfig = isMobile ? cartScreen?.wrapperMobile?.diningOptions : cartScreen?.wrapper?.diningOptions;
  const [orderNotes, setNotes] = useState(cart?.notes || '');
  const orderNotesCustomSettings =
    DiningOptionEnum.CURBSIDE === cart.diningOptionInfo?.type
      ? {
          label: webstore.metadata.curbsidePickupOrderNotesTitle,
          placeholder: webstore.metadata.curbsidePickupOrderNotesPlaceholder,
        }
      : { label: webstore.metadata.customMessages?.orderNotes.notesTitle, placeholder: webstore.metadata.customMessages?.orderNotes.notesPlaceholder };

  const handleChange = (e: any) => {
    setNotes(e as string);
  };

  const debounceHandleChange = useRef(
    debounce((orderNotes) => {
      const actions: ICartAction[] = [
        {
          type: CartActionTypeEnum.SET_NOTES,
          payload: {
            notes: orderNotes,
          },
        },
      ];
      if (cart?.notes !== orderNotes) {
        handleCartActions(actions);
        analytics.track('order_confirmation_page_set_actions', { actions });
      }
    }, 1500),
  ).current;

  useEffect(() => {
    debounceHandleChange(orderNotes);
  }, [debounceHandleChange, orderNotes]);

  return diningOptionsNotesConfig?.options?.showNotes ? (
    <Box testID="order-notes" marginBottom={8}>
      <Input
        testID="checkout-notes-input"
        label={orderNotesCustomSettings.label ?? 'Order Notes'}
        placeholder={orderNotesCustomSettings.placeholder ?? 'Max 320 characters'}
        multiline
        numberOfLines={4}
        borderRadius="md"
        value={orderNotes}
        style={{ height: 120, fontSize: '16', borderWidth: 1 }}
        onChangeText={handleChange}
      />
    </Box>
  ) : null;
};
