import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import * as React from 'react';

export const StoreAddress: React.FC = () => {
  const store = useStoreState();

  return (
    <Box marginBottom={4}>
      <Box mb={3}>
        <Text fontSize="m" fontWeight="600" lineHeight={24}>
          Store
        </Text>
      </Box>
      <Text lineHeight={24}>
        {store?.name} at {store.location?.formattedAddress}
      </Text>
    </Box>
  );
};
