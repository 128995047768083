import { getPromoTargets, isLoyalty, findPromoTarget } from './promotions';
import type { IOrderElement, IOrderPromotionElement } from '@goparrot/order-sdk';
import type { IPromotionWithApplicationState } from '@goparrot/promotions-sdk';
import type { ReadStoreLoyaltyRewardDto } from '@goparrot/loyalty-sdk';

interface Params {
  cartPromo?: IOrderPromotionElement;
  promotions?: IPromotionWithApplicationState[];
  loyalties?: ReadStoreLoyaltyRewardDto[];
  storeId?: string;
  item?: IOrderElement;
}

export const getPromoItemQtyInputProps = ({ cartPromo, promotions, loyalties, storeId, item }: Params) => {
  if (isLoyalty(cartPromo)) {
    const { availableApplicationCount } = loyalties?.find(({ rule }) => rule.promotionUuid === cartPromo?.uuid) ?? {};
    return { appliesNumber: availableApplicationCount ?? 0, defaultQty: 1 };
  }

  const { promotion, availableApplicationCount } = promotions?.find((p: any) => p.promotion?.uuid === cartPromo?.uuid) ?? {};
  const targets = getPromoTargets(promotion?.discount?.targets, storeId);
  const matchedTarget = findPromoTarget(targets, item);

  return { appliesNumber: availableApplicationCount ?? 0, defaultQty: matchedTarget?.quantity ?? 1 };
};
