import type { ReactElement } from 'react';
import React, { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

import { WrappedInlineLoader } from '../components/uielements/loaders';
import { DrawerWrapper } from '../shared/components/drawer';
import { lazyRetry } from '../shared/utils/lazy-retry';
import { NewPayment } from './checkout/new-payment';
import SuccessPageNew from './checkout/success/SuccessPageNew';
import ComboSwitcher from './combo/ComboSwitcher';
import { MENU_PATHS } from './paths';

const CartSwitch = lazy(async () => lazyRetry(async () => import('./cart/CartSwitch')));
const ItemCategory = lazy(async () => lazyRetry(async () => import('./item-category/ItemCategory')));
const EditUserProfile = lazy(async () => lazyRetry(async () => import('./edit-user/EditUserProfile')));
const LoyaltyInfo = lazy(async () => lazyRetry(async () => import('./loyalty-info/LoyaltyInfo')));
const PreviousOrders = lazy(async () => lazyRetry(async () => import('./previous-orders-page/PreviousOrders')));
const PreviousOrderPage = lazy(async () => lazyRetry(async () => import('./previous-order-page/PreviousOrderPage')));
const UserNotification = lazy(async () => lazyRetry(async () => import('./notification/UserNotification')));
const Promos = lazy(async () => lazyRetry(async () => import('./promos/Promos')));
const Profile = lazy(async () => lazyRetry(async () => import('./profile/Profile')));
const ItemModalEditPage = lazy(async () => lazyRetry(async () => import('./item-modal-edit/ItemModalEditPage')));
const ItemModalPage = lazy(async () => lazyRetry(async () => import('./item-modal/ItemModalPage')));
const ItemGroup = lazy(async () => lazyRetry(async () => import('./item-group/ItemGroup')));
const OrderSetupPage = lazy(async () => lazyRetry(async () => import('./order-setup/OrderSetupPage')));
const CheckoutComponent = lazy(async () => lazyRetry(async () => import('./checkout/CheckoutComponent')));
const LoginRegister = lazy(async () => lazyRetry(async () => import('./login-register/LoginRegister')));
const GiftCard = lazy(async () => lazyRetry(async () => import('./gift-card/GiftCard')));
const UserGiftCards = lazy(async () => lazyRetry(async () => import('./user-gift-cards/UserGiftCards')));
const AddExistingGiftCard = lazy(async () => lazyRetry(async () => import('./add-existing-gift-card/AddExistingGiftCard')));
const PromoItems = lazy(async () => lazyRetry(async () => import('./promo-items/PromoItems')));
const RewardItems = lazy(async () => lazyRetry(async () => import('./reward-items/RewardItems')));

interface DrawerWithSuspenseProps {
  children: ReactElement | ReactElement[];
  classNames?: string;
}

const DrawerWithSuspense: React.FC<DrawerWithSuspenseProps> = ({ children, classNames }) => {
  return (
    <DrawerWrapper classNames={classNames}>
      <Suspense fallback={<WrappedInlineLoader />}>{children}</Suspense>
    </DrawerWrapper>
  );
};

export const MENU_ROUTES: RouteObject[] = [
  {
    path: MENU_PATHS.cart,
    element: (
      <DrawerWithSuspense classNames="cart">
        <CartSwitch />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.payment,
    element: (
      <DrawerWithSuspense classNames="cart">
        <NewPayment />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.category,
    element: (
      <DrawerWithSuspense>
        <ItemCategory />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.edit_profile,
    element: (
      <DrawerWithSuspense classNames="modal__content--profile">
        <EditUserProfile />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.loyalty_info,
    element: (
      <DrawerWithSuspense>
        <LoyaltyInfo />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.last_orders,
    element: (
      <DrawerWithSuspense>
        <PreviousOrders />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.reorder,
    element: (
      <DrawerWithSuspense>
        <PreviousOrderPage />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.notifications,
    element: (
      <DrawerWithSuspense>
        <UserNotification />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.promos,
    element: (
      <DrawerWithSuspense>
        <Promos />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.profile,
    element: (
      <DrawerWithSuspense classNames="modal__content--profile">
        <Profile />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.edit_item_modal,
    element: (
      <DrawerWithSuspense>
        <ItemModalEditPage />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.item_modal,
    element: (
      <DrawerWithSuspense>
        <ItemModalPage />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.item_group,
    element: (
      <DrawerWithSuspense>
        <ItemGroup />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.order_setup,
    element: (
      <Suspense fallback={<></>}>
        <OrderSetupPage />
      </Suspense>
    ),
  },
  {
    path: MENU_PATHS.checkout,
    element: (
      <DrawerWithSuspense>
        <CheckoutComponent />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.order_complete,
    element: (
      <DrawerWithSuspense>
        <SuccessPageNew />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.login,
    element: (
      <DrawerWithSuspense>
        <LoginRegister />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.buy_gift_card,
    element: (
      <DrawerWithSuspense>
        <GiftCard />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.edit_gift_card,
    element: (
      <DrawerWithSuspense>
        <GiftCard />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.user_gift_cards,
    element: (
      <DrawerWithSuspense>
        <UserGiftCards />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.add_gift_card,
    element: (
      <DrawerWithSuspense>
        <AddExistingGiftCard />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.add_combo,
    element: (
      <DrawerWithSuspense>
        <ComboSwitcher />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.edit_combo,
    element: (
      <DrawerWithSuspense>
        <ComboSwitcher />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.promoItems,
    element: (
      <DrawerWithSuspense>
        <PromoItems />
      </DrawerWithSuspense>
    ),
  },
  {
    path: MENU_PATHS.rewardItems,
    element: (
      <DrawerWithSuspense>
        <RewardItems />
      </DrawerWithSuspense>
    ),
  },
];
