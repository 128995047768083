import type { IOrder } from '@goparrot/order-sdk';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import { DateTime } from 'luxon';
import * as React from 'react';

export interface OrderDateProps {
  lastOrder: IOrder;
}

const DATETIME_FORMAT = "MM/dd/yyyy 'at' hh:mm a";
export const OrderDate: React.FC<OrderDateProps> = ({ lastOrder }) => {
  const {
    dateInfo: { placedAt },
  } = lastOrder;
  const store = useStoreState();
  const formattedDate = DateTime.fromISO(placedAt as string, {
    zone: store.contactInfo.timeZone.id ?? 'America/New_York',
    locale: 'en-US',
  }).toFormat(DATETIME_FORMAT, { locale: 'en' });

  return (
    <Box marginBottom={4} testID="order-complete-date">
      <Box mb={3}>
        <Text fontSize="m" fontWeight="600" lineHeight={24}>
          Order date
        </Text>
      </Box>
      <Text lineHeight={24}>{formattedDate}</Text>
    </Box>
  );
};
