import { OrderFlowEnum, StoreItemTypeEnum } from '@goparrot/common';
import { isPackingInstruction } from '@goparrot/order-sdk';
import type { IStoreItemOptionSelection, IStoreItemOptionWithSelections } from '@goparrot/storeitems-sdk';
import { StoreItemsSelectionsUtil } from '@goparrot/storeitems-sdk';
import { OpenCollapseModifierGroupEnum } from '@goparrot/webstore-sdk';
import AsyncStorage from '@react-native-async-storage/async-storage';
import type { SelectableOrderElement } from '@webstore-monorepo/shared/api/cart-api';
import { useAddItemToCartMutations, usePackingInstructions, useRemoveItemFromCart } from '@webstore-monorepo/shared/api/cart-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { SelectablePackingInstructions } from '@webstore-monorepo/shared/interfaces';
import { Box } from '@webstore-monorepo/ui/box';
import filter from 'lodash/filter';
import type { MutableRefObject, RefObject } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { PackingInstructionItem } from './PackingInstructionItem';

export interface PackingInstructionsProps {
  isReadOnly?: boolean;
  itemRef?: MutableRefObject<RefObject<HTMLElement>[]>;
  onValidationChange: (valid: boolean, invalidIndex: number) => void;
}

const IS_DEFAULT_PACKING_INSTRUCTION_SET = 'LS_IS_DEFAULT_PACKING_INSTRUCTION_SET';

export const PackingInstructions: React.FC<PackingInstructionsProps> = ({ itemRef, isReadOnly, onValidationChange }) => {
  const cart = useCartState();
  const webstore = useWebStore();
  const cartDispatch = useCartDispatch();
  const { notification, analytics } = usePlatformStoreState();
  const { isLoading: isPackingLoading } = usePackingInstructions({
    options: {
      staleTime: 0,
      onSuccess: async (defaultPackingInstructions) => {
        let defaultsWereSet: boolean;
        try {
          defaultsWereSet = (await AsyncStorage.getItem(IS_DEFAULT_PACKING_INSTRUCTION_SET)) === 'true';
        } catch {
          defaultsWereSet = false;
        }

        if (defaultPackingInstructions) {
          const existingPackingInstructions: SelectableOrderElement[] = cart.elements.filter(isPackingInstruction).map((storeItem: SelectableOrderElement) => {
            if (storeItem.properties.some(({ selected }: { selected: boolean }) => selected)) {
              return {
                ...storeItem,
                selected: true,
              };
            }
            return storeItem;
          });
          const preSelectedUids: string[] = [];
          existingPackingInstructions.forEach((packing) => {
            preSelectedUids.push(...selectedSelections(packing));
          });
          const mappedInstructions = defaultPackingInstructions.map((storeItem) => {
            storeItem.properties = storeItem.properties.map((property: IStoreItemOptionWithSelections) => {
              const defaultSelections: string[] = property.metadata?.selections?.filter(({ ds }) => ds?.selected).map(({ uid }) => uid);
              const storeItemWasSelected: boolean =
                property.selections.some(({ uid }) => preSelectedUids.includes(uid)) || existingPackingInstructions.some(({ selected }) => selected);
              if (property.selections_min > 0 && property.selections.length === 1 && StoreItemTypeEnum.ITEM_BASE_GROUP !== property.selections[0].type) {
                property.selected = true;
                property.selections[0].selected = true;
                return property;
              }
              // if the property has maximum 1 selection and the user has already selected it, then we ignore the defaultSelections
              if (property.selections_max === 1) {
                const selection = property.selections.find(({ uid }) => preSelectedUids.includes(uid));

                if (selection) {
                  property.selections.forEach((s) => (s.selected = false));
                  storeItem.selected = true;
                  property.selected = true;
                  selection.selected = true;

                  return property;
                }
              }
              property.selections = property.selections.map((selection) => {
                if (
                  selection.isAvailable &&
                  (preSelectedUids.includes(selection.uid) || (!storeItemWasSelected && defaultSelections.includes(selection.uid)))
                ) {
                  storeItem.selected = true;
                  property.selected = true;
                  selection.selected = true;
                  return selection;
                }
                delete selection.selected;
                return selection;
              });
              if (!property.selected) {
                property.selected = !!existingPackingInstructions[0]?.properties?.find((prop: any) => prop.uid === property.uid)?.selected;
              }
              return property;
            });
            if (!storeItem.selected) {
              storeItem.selected = existingPackingInstructions[0]?.selected;
            }
            if (!storeItem.properties.length) {
              storeItem.selected = !!existingPackingInstructions.find((item) => item.uniqueName === storeItem.uniqueName);
            }
            return storeItem;
          });
          setPackingInstructions(mappedInstructions);

          if (!existingPackingInstructions.length && defaultPackingInstructions && !defaultsWereSet) {
            savePackingInstructions(defaultPackingInstructions, true);
          }
        }
      },
    },
  });
  const openCollapseModifierGroup = webstore.metadata?.modifiers?.openCollapseModifierGroup || OpenCollapseModifierGroupEnum.ALL;
  const [packingInstructions, setPackingInstructions] = useState<SelectablePackingInstructions[]>([]);
  const [isSavingDefaults, setIsSavingDefaults] = useState(false);
  const { mutateAsync: addItemToCart } = useAddItemToCartMutations({
    onSuccess: (cart, { item }) => {
      if (cart) {
        cartDispatch({ type: 'update', payload: cart });
        analytics.track('packing_instructions_add_item_to_cart', {
          uid: item.uniqueName,
        });
      }
    },
    onError: () => {
      notification.error('Sorry, this item cannot be added to your cart');
    },
  });

  const { mutateAsync: handleRemoveItem } = useRemoveItemFromCart({
    onSuccess: async (updatedCart, item) => {
      if (updatedCart) {
        cartDispatch({ type: 'update', payload: updatedCart });
        analytics.track('packing_instructions_item_remove', {
          uid: item.uniqueName,
        });

        return updatedCart;
      }
    },
    onError: () => {
      notification.error('Sorry, this item cannot be removed from your cart.');
    },
  });

  const selectedSelections = (item: any): string[] => {
    return Object.values(StoreItemsSelectionsUtil.getSelectionsMap(item, true)).flat();
  };

  const getInvalidPropIndex = (properties: IStoreItemOptionWithSelections[]) =>
    properties.findIndex((property) => {
      const allSelections: IStoreItemOptionSelection[] = property.selections?.reduce<IStoreItemOptionSelection[]>(
        (acc, prop) => (prop.type === StoreItemTypeEnum.ITEM_BASE_GROUP ? [...acc, ...prop.properties] : [...acc, prop]),
        [],
      );
      const selected: IStoreItemOptionSelection[] = filter(allSelections, 'selected');
      const selectedCount = selected.length;

      return property.selections_min && selectedCount < property.selections_min;
    });

  const validateSelection = useCallback(() => {
    const required = packingInstructions.reduce((accum: IStoreItemOptionWithSelections[][], item) => {
      if (item.properties?.length) {
        const requiredProperties = item.properties.filter((prop) => prop.selections_min > 0);
        if (requiredProperties.length) {
          accum.push(requiredProperties);
        }
      }
      return accum;
    }, []);

    let checkIfIsValid = true;
    if (required.length) {
      const flattedSelectionsProperties = required.flat();
      const selectedProps = flattedSelectionsProperties.filter((selection) => selection.selected);
      onValidationChange(false, packingInstructions[0] ? getInvalidPropIndex(packingInstructions[0].properties) : -1);

      if (flattedSelectionsProperties.length !== selectedProps.length) {
        return;
      }

      const preSelectedUids = packingInstructions.map((packing) => StoreItemsSelectionsUtil.getSelectionsMap(packing, true)).flat();

      checkIfIsValid = flattedSelectionsProperties.every((selection) => {
        const selectionLength = preSelectedUids[0][selection.uid].length;
        return selectionLength >= selection.selections_min && selectionLength <= selection.selections_max;
      });
    }

    onValidationChange(checkIfIsValid, packingInstructions[0] ? getInvalidPropIndex(packingInstructions[0].properties) : -1);
  }, [packingInstructions]);

  const handleUpdatePackingItem = async (packingItem: SelectablePackingInstructions) => {
    setPackingInstructions(packingInstructions.map((item) => (item.uniqueName === packingItem.uniqueName ? { ...packingItem } : item)));
    if (packingItem.selected) {
      await addItemToCart({ item: packingItem, quantity: 1, index: 0 });
    } else {
      await handleRemoveItem(packingItem);
    }
  };

  const savePackingInstructions = async (updatedPackagingInstructions: SelectablePackingInstructions[], isDefault = false) => {
    if (isDefault) {
      if (isSavingDefaults) {
        return;
      } else {
        setIsSavingDefaults(true);
      }
    }
    const selectedPackingInstructions = updatedPackagingInstructions.filter(({ properties }) => properties?.length);
    for (const item of selectedPackingInstructions) {
      const element = cart.elements.find((element) => element.uniqueName === item.uniqueName);
      const hasSelections = item.properties.some(({ selected, selections }) => selected && selections.some(({ selected }) => selected));
      if (!element && hasSelections) {
        await addItemToCart({ item, quantity: 1, index: 0 });
        break;
      }
      await handleRemoveItem(item);
      if (hasSelections) {
        await addItemToCart({ item, quantity: 1, index: 0 });
      }
    }
    if (isDefault) {
      AsyncStorage.setItem(IS_DEFAULT_PACKING_INSTRUCTION_SET, 'true');
      setIsSavingDefaults(false);
    }
  };

  useEffect(() => {
    if (packingInstructions) {
      validateSelection();
    }
  }, [packingInstructions, validateSelection]);

  const handleUpdatePackingInstructions = (uniqueName: string) => (properties: IStoreItemOptionWithSelections[]) => {
    const updated = packingInstructions.map((item) => (item.uniqueName === uniqueName ? { ...item, selected: true, properties: [...properties] } : item));
    setPackingInstructions(updated);
    savePackingInstructions(updated);
  };

  if (cart?.flow === OrderFlowEnum.DIGITAL) {
    return null;
  }

  return (
    <Box testID="packing-instructions">
      {packingInstructions?.map((packingInstructionsItem) => (
        <PackingInstructionItem
          itemRef={itemRef}
          key={packingInstructionsItem.uniqueName}
          isReadOnly={isReadOnly || isPackingLoading}
          packingInstructionsItem={packingInstructionsItem}
          openCollapseModifierGroup={openCollapseModifierGroup}
          onUpdateSingle={handleUpdatePackingItem}
          onUpdatePackingInstructions={handleUpdatePackingInstructions(packingInstructionsItem.uniqueName)}
        />
      ))}
    </Box>
  );
};
