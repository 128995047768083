import { UserTypeEnum } from '@goparrot/customer-sdk';
import type { IOrder } from '@goparrot/order-sdk';
import type { INestedStoreItemGroup, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { CategoryItemsGroup } from '@webstore-monorepo/features/category-items-group';
import { PreviousOrdersList } from '@webstore-monorepo/features/previous-orders-bar';
import { TopSellingItemsSlider } from '@webstore-monorepo/features/top-selling-slider';
import { useLastOrders, useWebStoreItems } from '@webstore-monorepo/shared/api/cart-api';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { RefObject } from 'react';
import React, { createRef, useCallback } from 'react';

import { useAppContextDispatch, useAppContextState } from '../../../../shared/contexts/app-context-provider';
import { useAnalytics } from '../../../../shared/hooks/use-analytics';
import { useAppHistory } from '../../../../shared/hooks/use-app-history';
import { useStoreStateItemUtils } from '../../../../shared/hooks/use-store-item-utils';
import { MENU_PATHS } from '../../../paths';
import * as s from './MenuList.styled';

interface Props {
  menuListCategoryItemsRef: RefObject<RefObject<HTMLDivElement>[]>;
  onAccNavHelperPressed: (id: string) => void;
  onPlaceholderRemoved: () => void;
}

export const MenuList: React.FC<Props> = ({ menuListCategoryItemsRef, onAccNavHelperPressed, onPlaceholderRemoved }) => {
  const webstore = useWebStore();
  const appState = useAppContextState();
  const userContext = useUserContextState();
  const appStateDispatch = useAppContextDispatch();
  // @ts-ignore
  const { topSellingCategoryUuid } = webstore.metadata;
  const history = useAppHistory();
  const analytics = useAnalytics();
  const { handleShowItemNew } = useStoreStateItemUtils();
  const { selectedMenuConceptUniqueNames } = appState;
  const { data: webstoreItems, isFetching: isItemsFetching } = useWebStoreItems({ selectedMenuConceptUniqueNames }, { staleTime: 0 });
  const showPreviousOrders = userContext?.user?.type === UserTypeEnum.AUTHENTICATED;
  const { data: lastOrders = [] } = useLastOrders({ enabled: showPreviousOrders, staleTime: Infinity });
  const customStyles = webstore.metadata.theme.menuList?.emptyMenuText;
  const emptyMenuText = webstore.metadata.customMessages?.menuList?.emptyMenuText;

  const handleOnItemCardClick = (item: IStoreItemWithOptions | INestedStoreItemGroup) => {
    handleShowItemNew({ item });
  };

  const handleOnTopItemCardClick = (item: IStoreItemWithOptions | INestedStoreItemGroup) => {
    handleShowItemNew({ item, fromTopSelling: true });
  };

  const handleShowPreviousOrder = (order: IOrder) => {
    history.push(MENU_PATHS.reorder.replace(':orderId', order._id), {
      fromOrderItem: true,
    });

    analytics.track('previous_order_select', { previousOrderId: order._id });
  };

  const handleShowPreviousOrders = () => {
    history.push(MENU_PATHS.last_orders);
  };

  const handleTopSellingWrapperRefChange = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        appStateDispatch({ type: 'update', payload: { topSellingItemsSliderRef: node, topSellingItemsSliderOffsetTop: node.offsetTop } });
      }
    },
    [appStateDispatch],
  );

  const handleContainerChange = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        const observer = new MutationObserver((mutationsList, observer) => {
          const lastMutation = mutationsList[mutationsList.length - 1];
          const lastNode = lastMutation.addedNodes[lastMutation.addedNodes.length - 1] as HTMLElement;
          if (lastNode.id === webstoreItems?.storeItemsByCategory[webstoreItems?.storeItemsByCategory.length - 1].uniqueName) {
            setTimeout(() => {
              onPlaceholderRemoved();
            }, 500);
            observer.disconnect();
          }
        });
        observer.observe(node, { childList: true });
      }
    },
    [onPlaceholderRemoved, webstoreItems?.storeItemsByCategory],
  );

  if (!webstoreItems?.storeItemsByCategory?.length && emptyMenuText) {
    return <s.EmptyMenuText customStyles={customStyles}>{emptyMenuText}</s.EmptyMenuText>;
  }

  return (
    <s.Container>
      <div className="col-main">
        <main id="main" className="site-main">
          {showPreviousOrders && lastOrders.length > 0 ? (
            <PreviousOrdersList onItemClick={handleShowPreviousOrder} onSeeMoreClick={handleShowPreviousOrders} />
          ) : null}

          {isItemsFetching ? (
            <div id="placeholder-menu" className="placeholder__menu">
              <div className="placeholder__mobile-card"></div>
              <div className="placeholder__mobile-card"></div>
              <div className="placeholder__mobile-card"></div>
              <div className="placeholder__mobile-card"></div>
              <div className="placeholder__mobile-card"></div>
              <div className="placeholder__mobile-card"></div>
              <div className="placeholder__card"></div>
              <div className="placeholder__card"></div>
              <div className="placeholder__card"></div>
              <div className="placeholder__card"></div>
              <div className="placeholder__card"></div>
              <div className="placeholder__card"></div>
              <div className="placeholder__card"></div>
              <div className="placeholder__card"></div>
            </div>
          ) : (
            <div ref={handleContainerChange}>
              <div id={topSellingCategoryUuid}>
                <TopSellingItemsSlider ref={handleTopSellingWrapperRefChange} onItemClick={handleOnTopItemCardClick} />
              </div>
              {webstoreItems?.storeItemsByCategory?.map((storeItemCategory, index) => {
                const { uniqueName } = storeItemCategory;

                return (
                  menuListCategoryItemsRef &&
                  menuListCategoryItemsRef.current && (
                    <CategoryItemsGroup
                      ref={(menuListCategoryItemsRef.current[index] = createRef())}
                      key={uniqueName}
                      id={uniqueName}
                      title={storeItemCategory.title}
                      subtitle={storeItemCategory.subtitle}
                      schedule={storeItemCategory.availabilitySchedule}
                      items={storeItemCategory.items}
                      onItemCardClick={handleOnItemCardClick}
                      onAccNavHelper={onAccNavHelperPressed}
                    />
                  )
                );
              })}
            </div>
          )}
        </main>
      </div>
    </s.Container>
  );
};
